import { StyledPropsTheme } from '@my-types';
import parent from './standard';

export default Object.assign({}, parent, {
	COLOR_PRIMARY: '#5ECA97', // 标准色
	COLOR_BACKGROUND:'#fff',
	BANK_COLOR:'#5ECA97',
	COLOR_PRIMARY_LIGHT_O75: 'rgba(94, 202, 151, 0.75)', // 标准色75%透明
	COLOR_PRIMARY_LIGHT_010: 'rgba(94, 202, 151, 0.1)',
	COLOR_PRIMARY_LIGHT: '#85d6b0', // 标准色淡10%
	INPUT_FOCUS_BORDER_COLOR: 'rgba(94, 202, 151, 0.8)', // 输入框获取焦点后边框色
	TOGGLE_TRUE_BORDER_COLOR: '#5ECA97',
	TOGGLE_TRUE_BACKGROUND_COLOR: '#5ECA97',
	COLOR_BACKGROUND_PERISON:'#5ECA97',
	COLOR_GRADIENT:'#5ECA97',

	ALERT_BOX_TOP: '35vh',
	ALERT_HINT_BACKGROUND_COLOR: 'rgba(0,0,0,.7)',
	ALERT_HINT_BORDER_RADIUS: '8px 8px 8px 8px',
	ALERT_HINT_COLOR: '#fff',
	ALERT_FADEAWAY_BACKGROUND_COLOR: 'rgba(0,0,0,.7)',
	ALERT_FADEAWAY_BORDER_RADIUS: '8px 8px 8px 8px',
	ALERT_FADEAWAY_COLOR: '#fff',
	ALERT_SHADOW: '5px 5px 8px rgb(0 0 0 / 35%)',
	ALERT_BUTTON_BAR_A_MAX_WIDTH: '33vw',
	ALERT_BUTTON_BAR_PADDING_TOP: '14px',
	ALERT_BUTTON_BAR_PADDING_BOTTOM: '20px',
	ALERT_BUTTON_HEIGHT: 30,
	ALERT_BTN_VERTICAL_PADDING: 5,
	ALERT_BUTTON_BAR_A_BORDER_TOP_LEFT_RADIUS: '24px',
	ALERT_BUTTON_BAR_A_BORDER_TOP_RIGHT_RADIUS: '24px',
	ALERT_BUTTON_BAR_A_BORDER_BOTTOM_LEFT_RADIUS: '24px',
	ALERT_BUTTON_BAR_A_BORDER_BOTTOM_RIGHT_RADIUS: '24px',
	ALERT_BORDER_RADIUS: '8px',
	ALERT_MSG_HEADER_DISPLAY:'block',
	ALERT_MSG_ICON_DISPLAY:'none',
	ALERT_MSG_LINE_TEXT_ALIGN:'center',
	ALERT_MSG_BLOCK_MIN_HEIGHT:'unset',
	ALERT_WAIVE_BTN_BACKGROUND_COLOR: '#fff',
	ALERT_WAIVE_BTN_COLOR:'#000',
	ALERT_WAIVE_BTN_BORDER:'1px solid #999',
	ALERT_BUTTON_BAR_A_WIDTH:'100px',
	ALERT_BUTTON_BAR_A_FLEX_GROW: 'unset',
	
}) as StyledPropsTheme;
