// EKANGJI
import { StyledPropsTheme } from '@my-types';
import parent from './standard';
import { HostBy } from '@common';

import PersonMsgImg from '@images/EKANGJI/personMsgImg.png';
import HeBiPersonMsgImg from '@images/EKANGJI/hebi/personMsgImg.png';

import Setting from '@images/EKANGJI/setting.png'
import HeBiSetting from '@images/EKANGJI/hebi/setting.png'

import Nopayment from '@images/EKANGJI/Nopayment.png'
import HeBiNopayment from '@images/EKANGJI/hebi/Nopayment.png'

import Failed from '@images/EKANGJI/Failed.png'
import HeBiFailed from '@images/EKANGJI/hebi/Failed.png'

import Pending from '@images/EKANGJI/Pending.png'
import HeBiPending from '@images/EKANGJI//hebi/Pending.png'

import Hasisused from '@images/EKANGJI/hasIsused.png'
import HeBiHasisused from '@images/EKANGJI/hebi/hasIsused.png'

import HmbLogo from "@images/EKANGJI/hmb_logo.png";
import HeBiHmbLogo from "@images/EKANGJI/hebi/hmb_logo.png";

import ImgTip from '@images/EKANGJI/img_tip.png';
import HeBiImgTip from '@images/EKANGJI/hebi/img_tip.png';

import EssentialInfor from '@images/EKANGJI/essentialInfor.png';
import HeBiEssentialInfor from '@images/EKANGJI/hebi/essentialInfor.png';

import Identity from '@images/EKANGJI/Identity.png';
import HeBiIdentity from '@images/EKANGJI/hebi/Identity.png';

import FirmYuLinBg from '@images/EKANGJI/firm_yulin_bg.png'
import FirmHeBiBg from '@images/EKANGJI/hebi/firm_hebi_bg.png'

import HomeHome from '@images/EKANGJI/home_home.png'
import HeBiHomeHome from '@images/EKANGJI/hebi/home_home.png'

import User from '@images/EKANGJI/user.png'
import HeBiUser from '@images/EKANGJI/hebi/user.png'

import HasPayed from '@images/EKANGJI/hasPayed.png'
import HeBiHasPayed from '@images/EKANGJI/hebi/hasPayed.png'

export const getHasPayed = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return HasPayed
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiHasPayed
	}
	return HasPayed;
}


export const getIconHomeHome = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return HomeHome
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiHomeHome
	}
	return HomeHome;
}

export const getIconUser = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return User
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiUser
	}
	return User;
}

const getPrimaryColor = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return '#EC642B'
		case HostBy.EkangjiProduct.HHB2022:
			return '#5A85FF'
	}
	return '#EC642B';
}

const getLinearGradient = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return 'linear-gradient(to right,#FF9300FF,#FF6622FF)'
		case HostBy.EkangjiProduct.HHB2022:
			return 'linear-gradient(to right,#799BFF,#5682FF)'
	}
	return 'linear-gradient(to right,#FF9300FF,#FF6622FF)';
}

const getPersonMsgImg = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return PersonMsgImg
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiPersonMsgImg
	}
	return PersonMsgImg;
}

const getSetting = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return Setting
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiSetting
	}
	return Setting;
}

const getNopayment = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return Nopayment
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiNopayment
	}
	return Nopayment;
}

const getFailed = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return Failed
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiFailed
	}
	return Failed;
}

const getPending = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return Pending
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiPending
	}
	return Pending;
}

const getHasisused = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return Hasisused
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiHasisused
	}
	return Hasisused;
}

const getHmbLogo = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return HmbLogo
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiHmbLogo
	}
	return HmbLogo;
}


const getImgTip = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return ImgTip
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiImgTip
	}
	return ImgTip;
}

const getEssentialInfor = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return EssentialInfor
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiEssentialInfor
	}
	return EssentialInfor;
}

const getIdentity = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return Identity
		case HostBy.EkangjiProduct.HHB2022:
			return HeBiIdentity
	}
	return Identity;
}

const getFirmBg = () => {
	switch (HostBy.asHostBy().productCode) {
		case HostBy.EkangjiProduct.YHB:
			return FirmYuLinBg;
		case HostBy.EkangjiProduct.HHB2022:
			return FirmHeBiBg;
		default:
			return FirmYuLinBg;
	}
}

export {
	getPrimaryColor,
	getLinearGradient,
	getPersonMsgImg,
	getHasisused,
	getPending,
	getFailed,
	getNopayment,
	getSetting,
	getHmbLogo,
	getImgTip,
	getEssentialInfor,
	getIdentity,
	getFirmBg
}




export default Object.assign({}, parent, {
	COLOR_PRIMARY: `${getPrimaryColor()}`, // 标准色
	COLOR_BACKGROUND: '#fff',
	BANK_COLOR: '#01CBE5',
	FONT_COLOR: '#0A0D2B',
	COLOR_PRICE: '#FF170D',
	FONT_COLOR_LIGHT2: '#9FA1B3',
	COLOR_BACKGROUND_PERISON: ' linear-gradient(90deg, rgba(58,228,226,0.53) 0%,rgba(83,192,231,0.53) 100%), url(v2_r2b2b2.png);',

	ALERT_BOX_TOP: '39vh',
	ALERT_HINT_BACKGROUND_COLOR: 'rgba(0,0,0,.7)',
	ALERT_HINT_BORDER_RADIUS: '8px 8px 8px 8px',
	ALERT_HINT_COLOR: '#fff',

	ALERT_FADEAWAY_BACKGROUND_COLOR: 'rgba(0,0,0,.7)',
	ALERT_FADEAWAY_BORDER_RADIUS: '8px 8px 8px 8px',
	ALERT_FADEAWAY_COLOR: '#fff',

	ALERT_SHADOW: '0px 2px 23px 0px rgba(227,228,233,1)',
	ALERT_BUTTON_BAR_A_MARGIN_BOTTOM: '20px',


	ALERT_BORDER_RADIUS: '2px',

	COLOR_PRIMARY_LIGHT_O75: 'rgba(226, 98, 39, 0.75)', // 标准色75%透明
	COLOR_PRIMARY_LIGHT_010: 'rgba(226, 98, 39, 0.1)',
	COLOR_PRIMARY_LIGHT: '#e88354', // 标准色淡10%
	INPUT_FOCUS_BORDER_COLOR: 'rgba(226, 98, 39, 0.8)', // 输入框获取焦点后边框色
	TOGGLE_TRUE_BORDER_COLOR: '#DE4E3E',
	TOGGLE_TRUE_BACKGROUND_COLOR: '#DE4E3E',

	// ALERT_SHADOW: '5px 5px 8px rgb(0 0 0 / 35%)',
	ALERT_BUTTON_BAR_PADDING_TOP: '13px',
	ALERT_BUTTON_HEIGHT: 30,
	ALERT_BTN_VERTICAL_PADDING: 5,

	ALERT_MSG_HEADER_DISPLAY: 'block',
	ALERT_MSG_HEADER_FONT_FAMILY: 'PingFangSC-Medium, PingFang SC',
	ALERT_MSG_HEADER_FONT_WEIGHT: '500',
	ALERT_MSG_HEADER_LINE_HEIGHT: '21px',
	ALERT_MSG_ICON_DISPLAY: 'none',
	ALERT_MSG_LINE_TEXT_ALIGN: 'center',
	ALERT_MSG_BLOCK_MIN_HEIGHT: 'unset',
	ALERT_WAIVE_BTN_BACKGROUND_COLOR: '#fff',
	ALERT_WAIVE_BTN_COLOR: '#000',



}) as StyledPropsTheme;