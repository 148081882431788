// IMPORTANT 这里不能随便import, 会引起不必要的打包引入
import * as History from 'history';
import * as StyledFunctions from 'styled-components';
import { createExternalModuleExport } from 'typescript';

/** 二维码参数 */
export type QrcodeOptions = {
	minVersion?: number;
	maxVersion?: number;
	ecLevel?: string;
	size?: number;
	/** color */
	fill?: string;
	background?: string;
	radius?: number;
	quiet?: number;
	mode?: number;
	mSize?: number;
	mPosX?: number;
	mPosY?: number;
	fontname?: string;
	fontcolor?: string;
	label?: string;
	image?: string;
};

/** 二维码产生器 */
export interface QRCodeGeneratorFunc {
	generate(text: string, options: QrcodeOptions): Promise<void>;

	getImage(): string;
}

/** 二维码模式 */
export class modes {
	NORMAL: number = 0;
	DRAW_WITH_LABEL_STRIP: number = 1;
	DRAW_WITH_LABEL_BOX: number = 2;
	DRAW_WITH_IMAGE_STRIP: number = 3;
	DRAW_WITH_IMAGE_BOX: number = 4;
}

/** 二维码质量等级 */
export class ecLevel {
	LOW: string = 'L';
	MEDIUM: string = 'M';
	QUARTILE: string = 'Q';
	HIGH: string = 'H';
}

/** 二维码库 */
export type QRCodeGeneratorLib = {
	qrcode: QRCodeGeneratorFunc & any;
	modes: modes;
	ecLevel: ecLevel;
};

export type ImageCompressorOptions = {
	toWidth?: number;
	toHeight?: number;
	/** default png */
	mimeType?: 'image/png' | 'image/jpeg';
	mode: 'strict' | 'stretch';
	/** 0 - 1, default 1 */
	quality?: number;
	/** default false */
	grayScale?: boolean;
	/** default false */
	sepia?: boolean;
	/** default false */
	threshold?: number | boolean;
	/** default false */
	vReverse?: boolean;
	/** default false */
	hReverse?: boolean;
	/** default low */
	speed?: 'low' | 'high';
};

export enum ImageCompressStrategy {
	BRAIN_POWER = 'BRAIN_POWER',//智能的
	UNUSE = 'UNUSE',//不使用
	USE = 'USE',
}

export interface ImageCompressorLib {
	new(): ImageCompressorLib;

	run: (
		image: string,
		options: ImageCompressorOptions,
		callback: (compressedImage: string) => void
	) => void;
}

/** 标准省份/城市/区县联动结构 */
export type StandardChinaRegionsType = {
	readonly PROVINCES: CodeItem[];
	readonly CITIES: {
		readonly [propName: string]: CodeItem[];
		readonly [index: number]: CodeItem[];
	};
	readonly DISTRICTS: {
		readonly [propName: string]: CodeItem[];
		readonly [index: number]: CodeItem[];
	};
	getCityList: (provinceCode: string | number) => CodeItem[];
	getDistrictList: (cityCode: string | number) => CodeItem[];
};

// 数据模型 *********************************************
/** 代理模型 */
export type ProxiedModel = {
	$$emitter: any;
	/** 返回自己 */
	$$on: (prop: string, listener: ModelDataChangeEventListener) => any;
	/** 返回自己 */
	$$off: (prop: string, listener: ModelDataChangeEventListener) => any;
	$$createEvent: (
		model: any,
		prop: string,
		oldValue: any,
		newValue: any
	) => ModelDataChangedEvent;
	$$fireEvent: (prop: string, oldValue: any, newValue: any) => void;
	/** 被代理的对象 */
	$$model: any;
	/** 是否是被代理过的对象, 如果没有被代理过, 通常值是undefined. 被代理过, 值是true */
	$$proxied: boolean;
	[propName: string]: any;
};
/** 模型属性值变化事件 */
export type ModelDataChangedEvent = {
	model: any;
	prop: string;
	oldValue?: any;
	newValue?: any;
};
/** 模型属性值变化监听器 */
export type ModelDataChangeEventListener = (
	event: ModelDataChangedEvent
) => void;
/** 简单属性 */
export type ParsedIdSimple = {
	id: string;
	type: 'simple';
};
/** 索引属性 */
export type ParsedIdIndex = {
	id: string;
	type: 'index';
	index: number;
};
/** 表达式属性 */
export type ParsedIdExpression = {
	id: string;
	type: 'expression';
	exact: boolean;
	filter: FilterExpression | FilterExpressionSimple;
};
/** 简单表达式解析结果 */
export type FilterExpressionSimple = {
	prop: string;
	operator: '=' | '==' | '<>' | '!=' | '>' | '>=' | '<' | '<=' | '===' | '!==';
	value: string;
};
/** 表达式 */
export type FilterExpression = {
	type: 'and' | 'or';
	expressions: (FilterExpression | FilterExpressionSimple)[];
};
/** 过滤器规则参数 */
export type FilterRuleOptions = {
	model: any;
	root: any;
	arrayHolder: any;
	rules: FilterRuleFunction[];
	caller: ModelWidgetInterface & WidgetHelped;
};
/** 过滤器规则函数 */
export type FilterRuleFunction = (
	caller: ModelWidgetInterface & WidgetHelped,
	options: FilterRuleOptions
) => boolean;
/** 复杂过滤器规则 */
export type ComplexFilterRule = {
	and?: string | string[] | (string | FilterRuleFunction)[] | FilterRuleFunction | FilterRuleFunction[];
	or?: string | string[];
};
/** 可过滤定义 */
export type Filterable = {
	on?:
	| string
	| string[]
	| FilterRuleFunction
	| FilterRuleFunction[]
	| ComplexFilterRule;
};
export type VisibleFilterable = { do?: boolean } & Filterable;
export type EditableFilterable = { do?: boolean } & Filterable;
/** 校验规则函数参数 */
export type CheckRuleOptions = {
	caller: ModelWidgetInterface & WidgetHelped;
	model: any;
	root: any;
	value: any;
	arrayHolder: any;
};
/** 预置校验规则函数参数 */
export type PredefinedCheckRuleOptions = {
	rule: CheckRule;
	params: any;
} & CheckRuleOptions;
/**
 * 自定义校验规则
 *
 * @return true表示通过, false表示没有通过校验
 */
export type CheckRuleFuncion = (options: CheckRuleOptions) => boolean | string;

/** 预定义的校验规则名称 */
export enum PredefinedCheckRules {
	REQUIRED = 'required',
	REGEXP = 'regexp',
	DATE_RANGE = 'dateRange',
	BANK_CARD_NUMBER = 'bankCardNumber',
	GENDER_ON_RELATION = 'genderOnRelation',
	AGE_RANGE = 'ageRange',
	NUMERIC_RANGE = 'numericRange',
	BMI = 'bmi',
	RELATION_WITH_POLICY_HOLDER = 'relationWithPolicyHolder',
	RELATION_WITH_POLICY_HOLDER_DUPLICATION = 'relationWithPolicyHolderDuplication',
	RELATION_WITH_MAIN_INSURED = 'relationWithMainInsured',
	RELATION_WITH_MAIN_INSURED_DUPLICATION = 'relationWithMainInsuredDuplication',
	RELATION_WITH_PH_AND_MI = 'relationWithPHAndMI',
	RELATION_WITH_INSURED = 'relationWithInsured',
	RELATION_WITH_INSURED_DUPLICATION = 'relationWithInsuredDuplication',
	ID_NUMBER = 'idNumber',
	ID_NUMBER_MATCH = 'idNumberMatch',
	PERMANENT_RESIDENT_PERMIT = 'permanentResidentPermit',
	CHINA_PASSPORT = 'chinaPassport',
	POST_CODE = 'postcode',
	NAME = 'name',
}

/** 校验规则, 必要项 */
export type CheckRuleRequired = {
	do: PredefinedCheckRules.REQUIRED;
};

export type CheckRuleName = {
	do: PredefinedCheckRules.NAME;
};

/** 预置正则校验规则名称, 参考predefined-regexp.tsx文件 */
export enum CheckRuleRegexpPredefinedSet {
	EMAIL = 'EMAIL',
	MOBILE = 'MOBILE',
	BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
	MILITARY_CERTIFICATE = 'MILITARY_CERTIFICATE',//军官证校验
	SOLDIER_CERTIFICATE = 'SOLDIER_CERTIFICATE',//士兵证校验
	IDENTITY_CARD = 'IDENTITY_CARD',
	POSTCODE = 'POSTCODE',
	CHINA_PASSPORT = 'CHINA_PASSPORT',
	TW_ID_CARD = 'TW_ID_CARD',
	TW_MAINLAND = 'TW_MAINLAND',
	HK_MAINLAND = 'HK_MAINLAND',
	DATE = 'DATE',
	SLOGAN_LENGTH = 'SLOGAN_LENGTH',
	HEIGHT_IN_CM = 'HEIGHT_IN_CM',
	HEIGHT_IN_CM_FRACTION = 'HEIGHT_IN_CM_FRACTION',
	HEIGHT_IN_CM_FRACTION2 = 'HEIGHT_IN_CM_FRACTION2',
	WEIGHT_IN_CATTY = 'WEIGHT_IN_CATTY',
	WEIGHT_IN_KG = 'WEIGHT_IN_KG',
	WEIGHT_IN_KG_FRACTION = 'WEIGHT_IN_KG_FRACTION',
	WEIGHT_IN_KG_FRACTION2 = 'WEIGHT_IN_KG_FRACTION2',
	ENGLISH_NAME = 'ENGLISH_NAME',
	DIGITS = 'DIGITS',
	FLOAT = 'FLOAT',
	HK_IDENTITY_CARD = 'HK_IDENTITY_CARD',
	TW_IDENTITY_CARD = 'TW_IDENTITY_CARD'
}

/** 校验规则, 正则表达式 */
export type CheckRuleRegexp = {
	do: PredefinedCheckRules.REGEXP;
	with:
	| {
		regexp: RegExp | CheckRuleRegexpPredefinedSet;
		contrary?: boolean;
	}
	| RegExp
	| CheckRuleRegexpPredefinedSet;
};
/** 校验规则, 日期区间 */
export type CheckRuleDateRange = {
	do: PredefinedCheckRules.DATE_RANGE;
	with: {
		/** 与当前日期进行比较, 使用startFrom/endWith的boolean值指定来表示与当前日期的关系 */
		now?: boolean;
		/** 与某个属性值进行比较. boolean值用于与now属性的结合 */
		startFrom?: string | boolean;
		/** 与某个属性值进行比较. boolean值用于与now属性的结合 */
		endWith?: string | boolean;
		/** 是否允许相等, 默认true */
		canEqual?: boolean;
		/** 指定用于比较的时间格式, 默认只有年月日, 时分秒都会被清除, 不参与比较 */
		format?: string;
		/** 指定被比较值的计算规则 */
		offset?: string;
	};
};
export type CheckRuleAgeRange = {
	do: PredefinedCheckRules.AGE_RANGE;
	with: string;
};
export type CheckRuleNumericRange = {
	do: PredefinedCheckRules.NUMERIC_RANGE;
	with: string;
};
/** 银行卡号校验 */
export type CheckRuleBankCardNumber = {
	do: PredefinedCheckRules.BANK_CARD_NUMBER;
	with?: {
		cardTypePropName?: string;
		bankCodePropName?: string;
		codeMapping?: { [propName: string]: string | number };
	};
};
export type CheckRuleGenderOnRelation = {
	do: PredefinedCheckRules.GENDER_ON_RELATION;
	with?: {
		relationPropName?: string;
	};
};
export type CheckRuleBmi = {
	do: PredefinedCheckRules.BMI;
	with: {
		range: string;
		heightPropName?: string;
		weightPropName?: string;
	};
};

export enum RelationTypeWith {
	SELF = '1', // 本人
	SPOUSE = '2', // 配偶
	CHILD = '3', // 子女
	PARENTS = '4', // 父母
	GRAND_PARENT = '5', // (外)祖父母
	GRAND_CHILDREN = '6', // 孙辈
	FOSTER_PARENT = '7', // 养父母
	EMPLOY = '8', // 雇佣
	HEIR_AT_LAW = '9', // 法定继承人
	SIBLING = '10', // 兄弟姐妹
	WIFE = '11', // 妻子
	HUSBAND = '12', // 丈夫
	SON = '13', // 儿子
	DAUGHTER = '14', //女儿
	FATHER = '15', // 父亲
	MOTHER = '16', // 母亲
	GRAND_SON_PARTRILOCAL = '17', // 孙子
	GRAND_DAUGHTER_PARTRILOCAL = '18', // 孙女
	GRAND_SON_MATRIARCHAL = '19', // 外孙
	GRAND_DAUGHTER_MATRIARCHAL = '20', // 外孙女
	GRAND_FATHER_PARTRILOCAL = '21', // 祖父
	GRAND_MOTHER_PARTRILOCAL = '22', // 祖母
	GRAND_FATHER_MATRIARCHAL = '23', // 外祖父
	GRAND_MOTHER_MATRIARCHAL = '24', // 外祖母
	UNCLE = '25', // 叔伯
	KINSFOLK = '26', // 亲属
	GENDER_PARTRILOCAL = '31',//孙子女
	GENDER_MATRIARCHAL = '32',//外孙子女
	SON_1 = '100',//大儿子
	SON_99 = '199', //小儿子
	DAUGHTER_1 = '200',//大女儿
	DAUGHTER_99 = '299',//小女儿
	GUARDIAN = '992',//监护人
	WORD = '993',//被监护人
	OTHER = '999' // 其他
}

export enum RelationTypeWithCheckType {
	AGE = 1,
	GENDER = 2
}

export type CheckRuleRelationWithPolicyHolder = {
	do: PredefinedCheckRules.RELATION_WITH_POLICY_HOLDER;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
		relationPropName: string;
		type: RelationTypeWithCheckType;
	};
};
export type CheckRuleRelationWithPolicyHolderDuplication = {
	do: PredefinedCheckRules.RELATION_WITH_POLICY_HOLDER_DUPLICATION;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
	};
};
export type CheckRuleRelationWithMainInsured = {
	do: PredefinedCheckRules.RELATION_WITH_MAIN_INSURED;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
		relationPropName: string;
		type: RelationTypeWithCheckType;
	};
};
/** 用于附加被保人与投保人关系栏位校验 */
export type CheckRuleRelationWithMainInsuredDuplication = {
	do: PredefinedCheckRules.RELATION_WITH_MAIN_INSURED_DUPLICATION;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
	};
};
/** 用于受益人与被保人之间的校验 */
export type CheckRuleRelationWithInsured = {
	do: PredefinedCheckRules.RELATION_WITH_INSURED;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
		relationPropName?: string;
		insuredPropName?: string;
		type: RelationTypeWithCheckType;
	};
};
/** 用于受益人与被保人之间的校验 */
export type CheckRuleRelationWithInsuredDuplication = {
	do: PredefinedCheckRules.RELATION_WITH_INSURED_DUPLICATION;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
	};
};
/** 用于附加被保人与投保人关系栏位校验 */
export type CheckRuleRelationWithPHAndMI = {
	do: PredefinedCheckRules.RELATION_WITH_PH_AND_MI;
	with: {
		/** 附加被保人与主保险人关系栏位 */
		relationPropName: string;
	};
};
export type CheckRuleIdNumber = {
	do: PredefinedCheckRules.ID_NUMBER;
	// idType字段名
	with: string;
};

export enum IdNumberMatchCheckType {
	DATE_OF_BIRTH = 1,
	GENDER = 2
}

export type CheckRuleIdNumberMatch = {
	do: PredefinedCheckRules.ID_NUMBER_MATCH;
	with: {
		type: IdNumberMatchCheckType;
		// 属性前缀
		prefix?: string;
	};
};
export type CheckRuleChinaPassport = {
	do: PredefinedCheckRules.CHINA_PASSPORT;
	// idType字段名
	with: string;
};
export type CheckRulePermanentResidentPermit = {
	do: PredefinedCheckRules.PERMANENT_RESIDENT_PERMIT;
	// 对象id
	with: string;
};
export type CheckRulePostCode = {
	do: PredefinedCheckRules.POST_CODE;
	// 对象id
	with: string;
};

/** 校验规则, 自定义函数 */
export type CheckRuleByFunction = {
	/** 执行的校验规则, 如果是string, 则执行预置规则 */
	do: CheckRuleFuncion;
};
export type CheckRuleMessage = { msg?: string };
/** 校验规则 */
export type CheckRule = (
	| CheckRuleRequired
	| CheckRuleName
	| CheckRuleRegexp
	| CheckRuleDateRange
	| CheckRuleAgeRange
	| CheckRuleNumericRange
	| CheckRuleBankCardNumber
	| CheckRuleGenderOnRelation
	| CheckRuleBmi
	| CheckRuleRelationWithPolicyHolder
	| CheckRuleRelationWithPolicyHolderDuplication
	| CheckRuleRelationWithMainInsured
	| CheckRuleRelationWithMainInsuredDuplication
	| CheckRuleRelationWithInsured
	| CheckRuleRelationWithInsuredDuplication
	| CheckRuleRelationWithPHAndMI
	| CheckRuleIdNumber
	| CheckRuleIdNumberMatch
	| CheckRuleChinaPassport
	| CheckRulePermanentResidentPermit
	| CheckRulePostCode
	| CheckRuleByFunction) &
	CheckRuleMessage &
	Filterable;
// 数据模型 *********************************************

// AJAX通讯 *********************************************
/** 请求HEADERS */
export type AjaxHeaders = {
	'Content-Type'?: string | boolean;
	[propName: string]: string | undefined | null | boolean;
};
/** 请求返回错误项目 */
export type AjaxResponseErrorItem = {
	code: string;
	description?: string | null;
};
/** 请求返回主体 */
export type AjaxResponseBody = {
	returnCode?: string | null;
	body?: object;
	errors?: AjaxResponseErrorItem[];
};
/** 标准请求返回 */
export type AjaxResponse = {
	headers?: AjaxHeaders;
	body?: AjaxResponseBody | any | null;
	status: number;
	statusText: string;
	error?: Error;
};

/** 请求方式 */
export enum AjaxMethod {
	GET = 'get',
	POST = 'post'
}

/** 请求参数 */
export type AjaxOptions = {
	method?: AjaxMethod;
	headers?: AjaxHeaders;
	credentials?: string;
	mode?: string;
	redirect?: string;
	cache?: string;

	dataType?: string;
	ignoreAuth?: boolean;
	ignoreRetrieveAccount?: boolean;
};
/** 请求响应处理器 */
export type AjaxHandler = (response: AjaxResponse) => void;
// AJAX通讯 *********************************************

// 账户 *********************************************
export type EnvAccount = {
	tenantId?: string | number | null;
	tenantCode?: string | null;
	organizationId?: string | number | null;
	type?: string | number | null;
	accountName?: string | null;
	nickname?: string | null;
	avatar?: string;
	menus?: string | null;
	salesAreaCode?: string | null;
	mobile?: string;
	mobileEncrypt?: string;
	email?: string;
	tenantName?: string | undefined;
	agentLicenseNo?: string;
	accountId: number | string | null;
	idType: number | string | null;
	idNo: number | string | null;
	showMall: number | string | null | any;
	needToSetPassword: number | string | null | any;
	sourceType: number | string | null | any;
	maskMobile: number | string | null | any;
	enableSendEmail?: boolean;
	organCode?: string;
	aesOrganCode?: string;
};
// 账户 *********************************************

// 应用 *********************************************
export interface Application {
	getTheme(): StyledPropsTheme;

	changeTheme(theme: string): void;
changeThemeColor(themeColor?: string): void;

	getThemeName(): string;

	setScrollDisabled(shown?: boolean): void;

	isScrollDisabled(): boolean;

	getHistory(): History.History;
}

// 应用 *********************************************

// STYLED-COMPONENTS *********************************************
/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
export type StyledPropsTheme = {
	/** 桌面HTML水平外边距 */
	readonly DESK_HTML_HORIZONTAL_MARGIN: number;
	/** 桌面滚动条宽度 */
	readonly DESK_HTML_SCROLL_WIDTH: number;
	/** 桌面按钮最小宽度 */
	readonly DESK_BTN_MIN_WIDTH: number;
	/** 桌面格子内部标签的宽度 */
	readonly DESK_CELL_INNER_LABEL_WIDTH: number;
	/** 错误提示淡出动画时间长度 */
	readonly ERROR_REMINDER_FADE_OUT_DURATION: string;
	/** 桌面对话框横向内边距 */
	readonly DESK_DIALOG_HORIZONTAL_PADDING: number;
	/** 桌面页眉高度 */
	readonly DESK_PAGE_HEADER_HEIGHT: number;
	/** 手机HTML水平外边距 */
	readonly MOBILE_HTML_HORIZONTAL_MARGIN: number;
	/** 手机页眉高度 */
	readonly MOBILE_PAGE_HEADER_HEIGHT: number;
	/** 手机页脚高度 */
	readonly MOBILE_PAGE_FOOTER_HEIGHT: number;
	/** 手机菜单按钮高度 */
	readonly MOBILE_MENU_ITEM_HEIGHT: number;
	/** 手机格子内部标签的宽度 */
	readonly MOBILE_CELL_INNER_LABEL_WIDTH: string;
	/** 页头层 */
	readonly PAGE_HEADER_Z_INDEX: number;
	/** 页脚层 */
	readonly PAGE_FOOTER_Z_INDEX: number;
	/** HTML字体 */
	readonly FONT_SIZE_HTML: number;
	/** 超大字体 */
	readonly FONT_SIZE_SUPER: number;
	/** 大字体4 */
	readonly FONT_SIZE_L4: number;
	/** 大字体3 */
	readonly FONT_SIZE_L3: number;
	/** 大字体2 */
	readonly FONT_SIZE_L2: number;
	/** 大字体 */
	readonly FONT_SIZE_LARGE: number;
	/** 标准字体 */
	readonly FONT_SIZE: number;
	/** 小字体 */
	readonly FONT_SIZE_SMALL: number;
	/** 标准字体 */
	readonly FONT_FAMILY: string;
	/** 标准字体称重 */
	readonly FONT_WEIGHT: number;
	/** 轻量加粗字体称重 */
	readonly FONT_WEIGHT_BOLD_LIGHT: number;
	/** 标准加粗字体称重 */
	readonly FONT_WEIGHT_BOLD: number;
	/** 标准字体色 */
	readonly FONT_COLOR: string;
	/** 淡化字体色 + 1 */
	readonly FONT_COLOR_LIGHT: string;
	/** 淡化字体色 + 2 */
	readonly FONT_COLOR_LIGHT2: string;
	/** 标准背景色 */
	readonly COLOR_BACKGROUND: string;
	/** 失效色 */
	readonly COLOR_DISABLED: string;
	/** 标准色 */
	readonly COLOR_PRIMARY: string;
	/** 标准色75%透明 */
	readonly COLOR_PRIMARY_LIGHT_O75: string;
	/** 标准色10%透明 */
	readonly COLOR_PRIMARY_LIGHT_010: string;
	/** 标准色淡10% */
	readonly COLOR_PRIMARY_LIGHT: string;
	/** 反转色 */
	readonly COLOR_REVERSED: string;
	/** 放弃色 */
	readonly COLOR_WAIVE: string;
	/** 放弃色淡10% */
	readonly COLOR_WAIVE_LIGHT: string;
	/** 消息色 */
	readonly COLOR_INFO: string;
	/** 危险色 */
	readonly COLOR_DANGER: string;
	/** 危险色淡10% */
	readonly COLOR_DANGER_LIGHT: string;
	/** 错误色 */
	readonly COLOR_ERROR: string;
	/** 成功色 */
	readonly COLOR_SUCCESS: string;
	/** 询问色 */
	readonly COLOR_QUESTION: string;
	/** 警告色 */
	readonly COLOR_WARN: string;
	/** 价格色 */
	readonly COLOR_PRICE: string;
	/** 标准边距, 适用于上下左右 */
	readonly MARGIN: number;
	/** 下方阴影 */
	readonly SHADOW_BOTTOM: string;
	/** 边框色 */
	readonly BORDER_COLOR: string;
	/** 轻度淡化边框色 */
	readonly BORDER_COLOR_MINOR_LIGHT: string;
	/** 淡化边框色 */
	readonly BORDER_COLOR_LIGHT: string;
	/** 边框宽度 */
	readonly BORDER_WIDTH: number;
	/** 边框样式 */
	readonly BORDER_STYLE: string;
	/** 边框圆角 */
	readonly BORDER_RADIUS: number;
	/** 边框 */
	readonly BORDER: string;
	/** 轻度淡化边框 */
	readonly BORDER_MINOR_LIGHT: string;
	/** 淡化边框 */
	readonly BORDER_LIGHT: string;
	/** 弹出层遮罩色 */
	readonly BACKDROP_COLOR: string;
	/** 对话框层 */
	readonly DIALOG_Z_INDEX: number;
	/** 对话框阴影 */
	readonly DIALOG_SHADOW: string;
	/** 底部弹出dialog的高度 */
	readonly DOWN_DIALOG_HEIGHT: number;
	/** 最大化弹出dialog的高度 */
	readonly MAX_DIALOG_HEIGHT: number;
	/** 警示框层 */
	readonly ALERT_Z_INDEX: number;
	/** 警示框阴影 */
	readonly ALERT_SHADOW: string;
	/** 警示框HINT背景色 */
	readonly ALERT_HINT_BACKGROUND_COLOR: string;
	/** 警示框HINT图标色 */
	readonly ALERT_HINT_ICON_COLOR: string;
	/** 警示框距离顶部高度 */
	readonly ALERT_BOX_TOP: string;
	readonly STANDARD_ALERT_BOX_TOP: string;
	/** 警示框按钮最大宽度 */
	readonly ALERT_BUTTON_BAR_A_MAX_WIDTH: string;
	readonly ALERT_BUTTON_BAR_PADDING_TOP: string;
	readonly ALERT_BUTTON_BAR_PADDING_BOTTOM: string;
	readonly ALERT_BUTTON_HEIGHT: number;
	readonly ALERT_BTN_VERTICAL_PADDING: number;
	readonly ALERT_BUTTON_BAR_A_BORDER_TOP_LEFT_RADIUS: string;
	readonly ALERT_BUTTON_BAR_A_BORDER_TOP_RIGHT_RADIUS: string;
	readonly ALERT_BUTTON_BAR_A_BORDER_BOTTOM_LEFT_RADIUS: string;
	readonly ALERT_BUTTON_BAR_A_BORDER_BOTTOM_RIGHT_RADIUS: string;
	readonly ALERT_BORDER_RADIUS: string;
	readonly STANDARD_ALERT_BORDER_RADIUS: string;
	readonly ALERT_MSG_LINE_TEXT_ALIGN: string;
	readonly ALERT_MSG_BLOCK_MIN_HEIGHT: string;
	readonly ALERT_WAIVE_BTN_BACKGROUND_COLOR: string;
	readonly ALERT_WAIVE_BTN_COLOR: string;
	readonly ALERT_WAIVE_BTN_BORDER: string;
	readonly ALERT_BUTTON_BAR_A_WIDTH: string;
	readonly ALERT_BUTTON_BAR_A_FLEX_GROW: string;
	/** 行高 */
	readonly ROW_HEIGHT: number;
	/** 中等行高 */
	readonly MIDDLE_HEIGHT: number;
	/** 矮的行高 */
	readonly SHORT_HEIGHT: number;
	/** 图标宽度 */
	readonly ICON_WIDTH: number;
	/** 输入框上下内边距 */
	readonly INPUT_VERTICAL_PADDING: number;
	/** 输入框左右内边距 */
	readonly INPUT_HORIZONTAL_PADDING: number;
	/** 输入框高度 */
	readonly INPUT_HEIGHT: number;
	/** 输入框文字行高 */
	readonly INPUT_LINE_HEIGHT: number;
	/** 输入框上下外边距 */
	readonly INPUT_VERTICAL_MARGIN: number;
	/** 输入框背景色 */
	readonly INPUT_BACKGRAOUND_COLOR: string;
	/** 输入框获取焦点后边框色 */
	readonly INPUT_FOCUS_BORDER_COLOR: string;
	/** 日期输入框三角符号颜色 */
	readonly DATE_CARET_COLOR: string;
	/** 日期输入框三角符号尺寸 */
	readonly DATE_CARET_SIZE: number;
	/** 下拉框三角符号颜色 */
	readonly SELECT_CARET_COLOR: string;
	/** 下拉框三角符号尺寸 */
	readonly SELECT_CARET_SIZE: number;
	/** 下拉框三角符号离右边距离 */
	readonly SELECT_CARET_RIGHT: number;
	/** 按钮上下内边距 */
	readonly BTN_VERTICAL_PADDING: number;
	/** 按钮左右内边距 */
	readonly BTN_HORIZONTAL_PADDING: number;
	/** 按钮文字行高 */
	readonly BTN_LINE_HEIGHT: number;
	/** 按钮高度 */
	readonly BTN_HEIGHT: number;
	/** 按钮组内部按钮间距 */
	readonly BTN_GROUP_GAP: number;
	/** 按钮组内部按钮间隔色 */
	readonly BTN_GROUP_GAP_COLOR: string;
	/** 开关按钮上下内边距 */
	readonly TOGGLE_VERTICAL_PADDING: number;
	/** 开关按钮高度 */
	readonly TOGGLE_HEIGHT: number;
	/** 开关按钮宽度 */
	readonly TOGGLE_WIDTH: number;
	/** 开关按钮滑块宽度 */
	readonly TOGGLE_SLIDER_WIDTH: number;
	/** 开关按钮边框色 */
	readonly TOGGLE_BORDER_COLOR: string;
	/** 开关按钮背景色 */
	readonly TOGGLE_BACKGROUND_COLOR: string;
	/** 开关按钮滑块色 */
	readonly TOGGLE_SLIDER_COLOR: string;
	/** 开关按钮true边框色 */
	readonly TOGGLE_TRUE_BORDER_COLOR: string;
	/** 开关按钮true背景色 */
	readonly TOGGLE_TRUE_BACKGROUND_COLOR: string;
	/** 开关按钮true滑块色 */
	readonly TOGGLE_TRUE_SLIDER_COLOR: string;
	/** 勾选框右边外边距 */
	readonly CHECKBOX_MARGIN_RIGHT: number;
	/** markdown正文行高 */
	readonly MARKDOWN_BODY_LINE_HEIGHT: number;
	/** markdown正文文字尺寸 */
	readonly MARKDOWN_BODY_FONT_SIZE: number;
	/** 表格双数行背景色 */
	readonly SPREAD_EVEN_BG_COLOR: string;
	/** 表格行Hover背景色 */
	readonly SPREAD_HOVER_BG_COLOR: string;
	/** 面板内边距 */
	readonly PANEL_PADDING: number;
	/** 面板背景色 */
	readonly PANEL_BACKGROUND_COLOR: string;
	/** 面板格子横向间距 */
	readonly PANEL_CELL_HORIZONTAL_GAP: number;
	/** 面板格子纵向间距 */
	readonly PANEL_CELL_VERTICAL_GAP: number;
	/** 面板头高度 */
	readonly PANEL_HEADER_HEIGHT: number;
	/** 面板格子横向间距 */
	readonly CONTAINER_CELL_HORIZONTAL_GAP: number;
	/** 面板格子纵向间距 */
	readonly CONTAINER_CELL_VERTICAL_GAP: number;
	/** 格子内部横向间距 */
	readonly CELL_INNER_HORIZONTAL_GAP: number;
	/** 格子内部纵向间距 */
	readonly CELL_INNER_VERTICAL_GAP: number;
	/** 格子内部底边间隔, 只在Labelling是生效 */
	readonly CELL_BOTTOM_BORDER_GAP: number;
	// 背景色
	readonly COLOR_BACKGROUND_PERISON: string;
	//品牌色
	readonly BANK_COLOR: string;
	//渐变
	readonly COLOR_GRADIENT: string

};

/** 可自定义CSS属性 */
export interface StyledPropsCss {
	/** CSS */
	css?: (props: any, theme: StyledPropsTheme) => string;
}

/** 主题属性 */
export interface StyledProps extends StyledPropsCss {
	theme: StyledPropsTheme;
}

/** 全局样式属性 */
export type GlobalStyledProps = {
	scrollDisabled?: boolean;
};

export type StyledDIV = StyledFunctions.StyledComponent<'div', any, {}, never>;
export type StyledSPAN = StyledFunctions.StyledComponent<'span',
	any,
	{},
	never>;
export type StyledBUTTON = StyledFunctions.StyledComponent<'a', any, {}, never>;
export type StyledINPUT = StyledFunctions.StyledComponent<'input',
	any,
	{ readOnly?: boolean },
	never>;
export type StyledSELECT = StyledFunctions.StyledComponent<'select',
	any,
	{ readOnly?: boolean },
	never>;
export type StyledI = StyledFunctions.StyledComponent<'i', any, {}, never>;
// STYLED-COMPONENTS *********************************************

// 基本组件属性 *********************************************
/** font-awesome图标属性 */
export interface FaIconProps extends StyledPropsCss {
	block?: boolean;
	color?: string;
	spin?: boolean;
	icon: string;
	className?: string;
	onClick?: (evt?: MouseEvent) => void;
	onTouchStart?: (evt?: TouchEvent) => void;
	onTouchEnd?: (evt?: TouchEvent) => void;
}

/** 输入框属性 */
export interface InputProps extends StyledPropsCss {
	type: string;
	placeholder?: string;
	maxLength?: number;
	disabled?: boolean;
	className?: string;
	name?: string;
	value?: any;
	autoComplete?: string;
	readOnly?: boolean;
	onFocus?: (evt?: FocusEvent) => void;
	onBlur?: (evt?: FocusEvent) => void;
	onChange?: (evt: React.FormEvent<HTMLInputElement>) => void;
}

/** 可点击的组件 */
export interface Clickable {
	onClick?: (
		invoker: ModelWidgetInterface & WidgetHelped,
		evt: MouseEvent
	) => void;
}

export enum ButtonType {
	PRIMARY = 'primary',
	WAIVE = 'waive',
	DANGER = 'danger'
}

export enum CheckValueType {
	BOOLEAN = 1,
	YES_NO = 2
}

/** 按钮属性 */
export interface ButtonProps extends StyledPropsCss, Clickable {
	type?: ButtonType;
	disabled?: boolean;
}

// 基本组件属性 *********************************************

// 组件 ********************************************
/** 组件属性 */
export interface IWidgetRouterHelper {
	forward(): this;

	back(): this;

	go(steps: number): this;

	pushRedirect(to: string): void;

	pushProtogeneRedirect(to: string): void;

	replaceRedirect(to: string): void;

	//非分享，网销产品从产品详情跳转至电投页时使用
	replaceProtogeneRedirect(to: string): void;

	gluttonReplace(to: string): Promise<void>;

	gluttonAssign(to: string): Promise<void>;

	gluttonHistoryGo(index: number): Promise<void>;

	gluttonHistoryBack(): Promise<void>;

	/**
	 * @deprecated never used
	 */
	windowReplace(to: string): Promise<void>;

	/**
	 * @deprecated never used
	 */
	windowAssign(to: string): Promise<void>;

	/**
	 * @deprecated never used
	 */
	windowHistoryGo(index: number): Promise<void>;

	/**
	 * @deprecated never used
	 */
	windowHistoryBack(): Promise<void>;
}

export type OPTIONS = AjaxOptions & {
	ignore4xx?: boolean;
	ignore5xx?: boolean;
	ignoreFail?: boolean;
	loading?: boolean;
};

export interface IWidgetAjaxHelper {
	post(url: string, data?: any, options?: OPTIONS): Promise<AjaxResponse>;

	get(url: string, data?: any, options?: OPTIONS): Promise<AjaxResponse>;

	all(promises: Promise<any>[]): Promise<any[]>;
}

export interface WidgetHelpers {
	getRouter(): IWidgetRouterHelper;

	getAjax(): IWidgetAjaxHelper;
}

export interface WidgetHelped {
	getHelpers(): WidgetHelpers;
}

export type WidgetProps = StyledPropsCss & {
	theme?: StyledPropsTheme;
};
export type ReadonlyProps = {
	/** 是否只读, 没有设置默认可操作 */
	readonly?: boolean;
};
export type InitProcessor = (options: InitProcessEvent) => void;
export type InitProcessEvent = {
	invoker: ModelWidgetInterface & WidgetHelped;
	[propName: string]: any;
}


export type ForceListener = (options: ForceListenEvent) => void;
export type ForceListenEvent = {
	invoker: ModelWidgetInterface & WidgetHelped;
	model: any;
	root: any;
	arrayHolder: any;
	value: any;
	oldValue: any;
};

/** 模型组件属性 */
export interface ModelWidgetInterface {
	getPropName(): string;

	getModel(): any;

	getRootModel(): any;

	getArrayHolder(): any;
}

export type ModelWidgetProps = {
	/** 属性名, 别名. 没有propName的时候生效 */
	id?: string;
	/** 属性名 */
	propName?: string;
	/** 模型 */
	model?: object;
	/** 根模型 */
	root?: object;
	/** 如果本组件是数组元素, 则会有数组持有者 */
	arrayHolder?: object;
	/** 是否显示 */
	visible?: false | string | string[] | Filterable | Filterable[];
	/** 监听器, 在值变化的时候触发 */
	listen?: ForceListener;
	/** 组件初始处理器 */
	process?: InitProcessor;
} & WidgetProps;
/** 可校验组件属性 */
export type CheckableWidgetProps = {
	/** 校验规则 */
	check?: boolean | CheckRule | CheckRule[];
	checkStages?: string | string[];
	/** 校验规则前置条件, 不满足则不触发校验规则 */
	triggerCheckOn?: string | string[] | Filterable | Filterable[];
	/**即时展示错误信息 */
	reminderShow?: boolean;
	/** 触发校验方式 */
	checkType?: 'blur';
} & ModelWidgetProps;
/** 可校验组件状态 */
export type CheckableWidgetState = {
	/** 是否因为某种原因导致校验失败, 原因无论 */
	checkFailOnWhatever?: boolean;
	/** 校验失败原因信息 */
	checkFailMsg?: string;
};
/** 可校验组件校验结果 */
export type CheckableWidgetCheckResult = {
	/** 未通过的校验规则 */
	rule?: CheckRule;
	/** 什么组件发生校验失败 */
	where: any;
};
/** 码表元素 */
export type CodeItem = {
	/** 显示标签 */
	l: string;
	/** 码值 */
	v: string | number | boolean;
	/** 上级值 */
	p?: string | number;
};
/** 码表组件属性 */
export type CodesWidgetProps = {
	clearable?: boolean;
	codes: CodeItem[];
};
export type CodesFilter =
	| {
		filter: (
			codes: any,
			invoker: ModelWidgetInterface & WidgetHelped
		) => CodeItem[];
		on: string;
	}
	| ((codes: any, invoker: ModelWidgetInterface & WidgetHelped) => CodeItem[]);

/** 码表组件 */
export interface CodesWidget {
	isClearable(): boolean;

	getCodes(): CodeItem[];
}

/** 错误提醒组件属性 */
export type ErrorReminderProps = {
	/** 提醒内容 */
	msg: string;
};
export type LabelDecoratorOptions = {
	root: any;
	model: any;
	arrayHolder: any;
	value: any;
	invoker: ModelWidgetInterface & WidgetHelped;
	decorated: any;
};
export type LabelDecorator = (options: LabelDecoratorOptions) => any;
export type LabelCss = (props: any, theme: StyledPropsTheme) => string;

/** 页眉内部组件 */
export type PageHeaderComponents = {
	Box: StyledDIV;
};
/** 页面主体内部组件 */
export type PageBodyComponents = {
	Box: StyledDIV;
};
/** 页脚内部组件 */
export type PageFooterComponents = {
	Box: StyledDIV;
};
/** 页面内部组件 */
export type PageComponents = {
	Box: StyledDIV;
};

// 桌面组件 *******************************************************
/** 桌面页眉内部组件 */
export type DeskPageHeaderComponents = {
	Logo: StyledI;
	LogoText: StyledDIV;
} & PageHeaderComponents;
/** 桌面页眉菜单集内部组件 */
export type DeskPageHeaderMenusComponents = {
	Box: StyledDIV;
};
/** 桌面页眉标准菜单内部组件 */
export type DeskPageHeaderMenuComponents = {
	Box: StyledDIV;
	SubMenus: StyledDIV;
	SubMenu: StyledDIV;
	SubMenus2: StyledDIV;
	SubMenu2: StyledDIV;
};
/** 桌面页眉右菜单内部组件 */
export type DeskPageHeaderRightMenuComponents = {
	Box: StyledDIV;
	AccountText: StyledDIV;
	SignOut: StyledDIV;
};
/** 桌面页面主体属性 */
export type DeskPageBodyProps = {
	home?: boolean | null;
	where?: string | string[] | null;
};
/** 桌面页面主体内部组件 */
export type DeskPageBodyComponents = {
	LOC: StyledDIV;
	Home: StyledSPAN;
} & PageBodyComponents;
/** 桌面404页面主体内部组件 */
export type DeskNotFoundBodyComponents = {
	ICONS: StyledDIV;
	ICON: StyledSPAN;
	MSG: StyledDIV;
} & DeskPageBodyComponents;

// desk container
/** Flex容器对齐属性枚举 */
export enum FlexAlignment {
	START = 'start',
	END = 'end',
	CENTER = 'center',
	BETWEEN = 'between',
	AOURND = 'around',
	EVENLY = 'evenly'
}

/** Flex容器属性 */
export type FlexContainerProps = {
	align?: FlexAlignment;
} & ModelWidgetProps;
/** 网格容器属性 */
export type GridContainerProps = {
	/**
	 * 网格布局列数量, 如果定义了模板则不会生效.
	 * 生效时将容器按照列数量均分
	 */
	gridColumns?: number;
	/**
	 * 网格布局模板, 最优先生效
	 */
	gridTemplates?: string;
} & ModelWidgetProps;
/** 网格单元格属性 */
export type GridCellProps = {
	row?: number | string | null;
	rowSpan?: number | string | null;
	column?: number | string | null;
	columnSpan?: number | string | null;
};
/** 容器属性 */
export type ContainerProps = {
	layout?: object;
} & GridContainerProps;
/** 容器内部组件 */
export type ContainerComponents = {
	Box: StyledDIV;
};
/** 数组容器属性 */
export type ArrayContainerProps = FlexContainerProps;
/** 数组容器内部组件 */
export type ArrayContainerComponents = { Box: StyledDIV };
/** 数组容器行集合属性 */
export type ArrayContainerRowsProps = {
	nodata: ArrayContainerNoDataProps;
	sort?: (a: any, b: any) => number;
} & ModelWidgetProps;
/** 数组容器行属性 */
export type ArrayContainerRowProps = { index: number } & FlexContainerProps;
/** 数组容器行内部组件 */
export type ArrayContainerRowComponents = { Box: StyledDIV };
/** 数组容器无数据属性 */
export type ArrayContainerNoDataProps = {
	/** 显示标签 */
	label?: string | false;
} & ModelWidgetProps &
	FlexContainerProps;
export type ArrayContainerNoDataComponents = { Box: StyledDIV };
/** 面板属性 */
export type DeskPanelProps = {
	collapsible?: boolean;
	expanded?: boolean;
} & ModelWidgetProps;
/** 面板状态 */
export type DeskPanelState = { expanded?: boolean };
/** 面板内部组件 */
export type DeskPanelComponents = { Box: StyledDIV };
/** 面板头内部组件 */
export type DeskPanelHeaderComponents = { Box: StyledDIV };
/** 面板头标题内部组件 */
export type DeskPanelHeaderTitleComponents = { Box: StyledDIV };
/** 面板头折叠/展开图标属性 */
export type DeskPanelHeaderCollapsibleIconProps = {
	expanded: boolean;
	onClick: () => void;
} & WidgetProps;
/** 面板头折叠/展开图标内部组件 */
export type DeskPanelHeaderCollapsibleIconComponents = {
	Box: any;
};
/** 面板主体属性 */
export type DeskPanelBodyProps = {} & GridContainerProps;
/** 面板主体内部组件 */
export type DeskPanelBodyComponents = { Box: StyledDIV };
/** 面板主体单元格属性 */
export type DeskPanelBodyCellProps = {} & GridCellProps & FlexContainerProps;
/** 面板主体单元格内部组件 */
export type DeskPanelBodyCellComponents = { Box: StyledDIV };
/** 面板脚属性 */
export type DeskPanelFooterProps = {} & FlexContainerProps;
/** 面板脚内部组件 */
export type DeskPanelFooterComponents = { Box: StyledDIV };
/** 可以做为标签化组件父组件的面板主体单元格组件属性 */
export type DeskPanelLabelledBodyCellProps = {
	/**
	 * 标签文本
	 */
	label?: string;
	/**
	 * 标签宽度, 百分比数字
	 */
	labelWidth?: number;
} & DeskPanelBodyCellProps;
/** 可以做为标签化组件父组件的面板主体单元格组件状态 */
export type DeskPanelLabelledBodyCellState = {
	focus?: boolean;
	checkFailed?: boolean;
};
/** 可以做为标签化组件父组件的面板主体单元格组件内部组件 */
export type DeskPanelLabelledBodyCellComponents = { Box: StyledDIV };

/** 可以包含标签化组件的父组件接口 */
export interface LabellingParent {
	/** 返回当前组件的数据是否有必填要求 */
	isDataRequired(): boolean;

	/** 强制让被标签的组件获取焦点 */
	focusLabelled: () => void;
	/** 获取焦点后处理 */
	onFocusGained: () => void;
	/** 失去焦点后处理 */
	onFocusLost: () => void;
	/** 校验完毕后处理 */
	onCheckCompleted: (passed: boolean) => void;
}

/** 可以被标签化的组件属性ƒ */
export type LabellingComponentProps = {
	/** 标签化父节点 */
	labellingParent?: LabellingParent;
};
/** 按钮属性 */
export type DeskButtonProps = {
	label?: string;
} & WidgetProps &
	ButtonProps;
/** 按钮内部组件 */
export type DeskButtonComponents = {
	Button: StyledBUTTON & React.ComponentClass<ButtonProps>;
};
/** 链接属性 */
export type DeskLinkProps = {
	label?: string;
} & WidgetProps &
	Clickable;
/** 链接内部组件 */
export type DeskLinkComponents = {
	Link: StyledBUTTON & React.ComponentClass<Clickable>;
};
/** 按钮组内部组件 */
export type DeskButtonGroupComponents = { Box: StyledDIV };
/** 桌面标签内部组件 */
export type DeskLabelProps = { label?: string } & LabellingComponentProps &
	ModelWidgetProps &
	WidgetProps;
/** 桌面标签内部状态 */
export type DeskLabelState = { focus?: boolean };
/** 桌面标签内部组件 */
export type DeskLabelComponents = { Box: StyledSPAN };
/** 桌面作为组件标签出现的标签属性 */
export type DeskLabellingLabelProps = {
	required?: boolean;
} & DeskLabelProps &
	LabellingComponentProps;
export type DeskCodesLabelProps = {} & CodesWidgetProps & DeskLabelProps;
/** 桌面输入框属性 */
export type DeskTextProps = {
	placeholder?: string;
} & LabellingComponentProps &
	CheckableWidgetProps &
	ReadonlyProps;
/** 桌面输入框内部组件 */
export type DeskTextComponents = {
	Box: StyledDIV;
	Text: StyledINPUT | any;
	ErrorReminder: any;
};
/** 桌面日期输入框属性 */
export type DeskDateProps = { format?: string } & DeskTextProps;
/** 桌面日期输入框内部组件 */
export type DeskDateComponents = {
	DisplayLabel: StyledSPAN;
	DisplayLabelCaret: StyledI;
} & DeskTextComponents;
/** 桌面下拉框属性 */
export type DeskSelectProps = {} & ReadonlyProps &
	LabellingComponentProps &
	CheckableWidgetProps &
	CodesWidgetProps;
/** 桌面下拉框内部组件 */
export type DeskSelectComponents = {
	Box: StyledDIV;
	Select: StyledSELECT;
	ErrorReminder: any;
};
/** 桌面勾选框属性 */
export type DeskCheckBoxProps = {} & LabellingComponentProps &
	WidgetProps &
	ReadonlyProps;
/** 桌面勾选框内部组件 */
export type DeskCheckBoxComponents = {
	Box: StyledINPUT;
};
/** 桌面开关按钮属性 */
export type DeskToggleProps = {} & LabellingComponentProps &
	WidgetProps &
	ReadonlyProps;
/** 桌面开关按钮内部组件 */
export type DeskToggleComponents = {
	Box: StyledDIV;
	InnerBox: StyledDIV;
	Slider: StyledSPAN;
};
/** 桌面文件选择器属性 */
export type DeskFilePickerProps = { accept?: string } & DeskTextProps;
export type DeskFilePickerState = {
	fileName?: string;
	imageSource?: string;
} & CheckableWidgetState;
/** 桌面文件选择器内部组件 */
export type DeskFilePickerComponents = {
	PlaceHolder: StyledSPAN;
} & DeskTextComponents;
export type DeskImagePickerProps = { url: string } & DeskTextProps;
/** 桌面表格属性 */
export type DeskSpreadSheetProps = {
	/** 无数据显示CSS */
	noDataCss?: (props?: any) => string;
	/** 行显示CSS */
	rowCss?: (props?: any) => string;
} & WidgetProps;
/** 桌面表格内部组件 */
export type DeskSpreadSheetComponents = {
	Box: StyledDIV;
};
/** 桌面表格头内部组件 */
export type DeskSpreadSheetHeaderComponents = {
	Box: StyledDIV;
};
/** 桌面表格单元格属性 */
export type DeskSpreadSheetCellProps = {} & GridCellProps & ModelWidgetProps;
/** 桌面表格单元格内部组件 */
export type DeskSpreadSheetCellComponents = { Box: StyledDIV };
/** 桌面表格行集合属性 */
export type DeskSpreadSheetBodyRowsProps = {
	/** 是否描画无数据, 只有false才会关闭 */
	nodata?: { label?: boolean };
} & ModelWidgetProps;
/** 桌面表格行属性 */
export type DeskSpreadSheetBodyRowProps = {} & GridContainerProps;
/** 桌面表格行内部组件 */
export type DeskSpreadSheetBodyRowComponents = {
	Box: StyledDIV;
};
export type DeskSpreadSheetFooterProps = {};
export type DeskSpreadSheetFooterComponents = { Box: StyledDIV };
/** 桌面表格无数据属性 */
export type DeskSpeadSheetNoDataProps = {
	/** 显示标签 */
	label?: string | false;
} & ModelWidgetProps &
	FlexContainerProps;
/** 桌面表格无数据内部组件 */
export type DeskSpeadSheetNoDataComponents = {
	Box: StyledDIV;
};
/** 翻页对象 */
export type Pagination = {
	/** 从0开始计数 */
	pageNumber: number;
	pageCount: number;
	pageSize: number;
};
/** 翻页变动事件 */
export type PaginationChangeEvent = Pagination & {
	invoker: ModelWidgetInterface & WidgetHelped;
};
export type DeskPaginationProps = {
	onPageChanged?: (evt: PaginationChangeEvent) => void;
} & ModelWidgetProps;
/** 桌面分页内部组件 */
export type DeskPaginationComponents = {
	Box: StyledDIV;
	Arrow: any;
	Button: StyledBUTTON;
	TotalCount: StyledDIV;
};
// 桌面组件 *******************************************************
export type OtherSmallImageCreator = (url: string) => any;
export type OtherSmallImageArrayOptions = {
	model: any;
	root: any;
	arrayHolder: any;
	item: any;
	invoker: ModelWidgetInterface & WidgetHelped;
};
export type OtherSmallImageArrayOperator = (
	options: OtherSmallImageArrayOptions
) => void;
export type OtherSmallImageFileCountOperator = (
	options: OtherSmallImageArrayOptions
) => number;
// 组件 ********************************************

// 系统健康 ********************************************
export type ActuatorResultProps = {
	data?: any | null;
} & WidgetProps;
export type ActuatorResultComponents = {
	Box: StyledDIV;
};
export type ActuatorBodyState = {
	data?: any | null;
};
export type LogDownloadComponents = {
	ContentBox: StyledDIV;
} & DeskPageBodyComponents;
export type LogLevelComponents = {
	ContentBox: StyledDIV;
	Row: StyledDIV;
	Category: StyledINPUT;
	Level: StyledSELECT;
	Confirm: StyledBUTTON;
} & DeskPageBodyComponents;
// 系统健康 ********************************************

// 登录 ********************************************
export type SignCommonPageComponents = {
	TopBlank: StyledDIV;
	Form: StyledDIV;
	InputRow: StyledDIV &
	React.ComponentClass<{
		focus?: boolean;
	}>;
	Icon: StyledI & React.ComponentClass<FaIconProps>;
	Input: StyledINPUT & React.ComponentClass<InputProps>;
	Row: StyledDIV;
	SignBtn: StyledBUTTON;
	Link: StyledBUTTON;
	RegisterRow: StyledDIV;
} & PageComponents;

export interface SignCommonPageProps extends StyledProps {
	focus?: boolean;
}

export type SignCommonPageState = {
	focus?: React.RefObject<HTMLDivElement>;
};

export type MejSignCommonPageComponents = {
	Row: StyledDIV & React.ComponentClass<StyledPropsCss>;
	LineButton: StyledBUTTON & React.ComponentClass<ButtonProps>;
	Link: HTMLAnchorElement &
	React.ComponentClass<Clickable & {
		className?: string;
		disabled?: boolean;
	}>;
	Input: StyledINPUT & React.ComponentClass<InputProps>;
	DialogWrapper: StyledDIV & React.ComponentClass<StyledPropsCss>;
	DialogButton: StyledBUTTON & React.ComponentClass<ButtonProps>;
	FullFormControl: StyledDIV & React.ComponentClass<StyledPropsCss & {
		className?: string;
	}>;
} & PageComponents;

// 登录 ********************************************

export enum SignatureServerSideDocType {
	CLIENT_NOTICE = '1',
	CONSIGNMENT_AGREEMENT = '2',
	RECEIPT = '3',
	INSURANCE_CUSTOMER_NOTIFICATION = '4',
	ELECTRONIC_INSURANCE_FORM = '5',
	PERSONAL_TAX_RESIDENT = '7',
	PERSONAL_INSURANCE_REMINDER = '8',
	FOREIGN_QUESTION_SURVEY = '10',// 非大陆人士问卷,
	LIABILITY_EXEMPTION_CLAUSES = '11', //免除保险人责任条款的说明书,
	CASE_DETAIL_SELECTION = '9',//方法详情及方案选择表
	CHONGQING_QUESTION_SURVEY = '12', //重庆地区问卷调查
	ELECTRONIC_CONFIRM_FORM = '13',//电子投保确认函
	BANK_AUTO_TRANSFER_ANTHORIZATION_SURVEY = '14', //保险款项自动转账授权书
	INSURANCE_CONFIRM_FORM = '15', //投保确认书
	INSURED_RELATIONSHIP_IDTIFY = '18',//新契约投保身份关系声明
	CHONGQING_RISK_HINT = '20', //风险提示



}

// 保单模型 ********************************************
export enum Gender {
	MALE = 'M',
	FEMALE = 'F'
}

export enum ParticipantIdType {
	/** 身份证 */
	ID_CARD = 1,
	/** 户口本 */
	FAMILY_REGISTER = 2,
	/** 出生证明 */
	BIRTH_CERTIFICATE = 3,
	/** 驾照 */
	DRIVE_LICENSE = 4,
	/** 军官证 */
	MILITARY_ID = 5,
	/** 护照 */
	PASSPORT = 6,
	/** 港澳通行证 */
	HK_PASS = 7,
	/** 台湾通行证 */
	TW_PASS = 8,
	/** 外国护照 */
	// FOREIGN_PASSPORT = 9,
	/** 居住证 */
	FOREIGN_REGISTER_CARD = 10,
	/** 港澳居民来往内地通行证 */
	HK_MAINLAND = 11,
	/** 台湾居民来往大陆通行证 */
	TW_MAINLAND = 12,
	/** 香港/澳门居民居住证 */
	HK_RESIDENT_PERMIT = 13,
	/** 台湾居民居住证 */
	TW_RESIDENT_PERMIT = 14,
	/** 外国人永久居留身份证 */
	PERMANENT_RESIDENT_PERMIT = 15,
	/** 警官证 */
	POLICE_OFFICER_CERTIFICATE = 16,
	/** 临时身份证 */
	TEMPORARY_ID_CARD = 17,
	/** 士兵证 */
	SOLDIER_ID_CARD = 18,
	/** 军官退休证 */
	RETIRE_MILITARY_ID = 19,
	/** 其他证件 */
	OTHERS = 99
}

export type ParticipantDeclaration = {
	code?: string;
	answer?: boolean;
	remark?: string;
	version?: string;
	parentCode?: string;
	parameters?: string;
	parameterObj?: string;
};

export enum PolicyDocumentType {
	/** 身份证 */
	ID_CARD = 1,
	/** 户口本 */
	FAMILY_REGISTER = 2,
	/** 出生证明 */
	BIRTH_CERTIFICATE = 3,
	/** 驾照 */
	DRIVE_LICENSE = 4,
	/** 军官证 */
	MILITARY_ID = 5,
	/** 护照 */
	PASSPORT = 6,
	/** 港澳通行证 */
	HK_PASS = 7,
	/** 台湾通行证 */
	TW_PASS = 8,
	/** 外国护照 */
	// FOREIGN_PASSPORT = 9,
	/** 居住证 */
	FOREIGN_REGISTER_CARD = 10,
	/** 港澳居民来往内地通行证 */
	HK_MAINLAND = 11,
	/** 台湾居民来往大陆通行证 */
	TW_MAINLAND = 12,
	/** 香港/澳门居民居住证 */
	HK_RESIDENT_PERMIT = 13,
	/** 台湾居民居住证 */
	TW_RESIDENT_PERMIT = 14,
	/** 外国人永久居留身份证 */
	PERMANENT_RESIDENT_PERMIT = 15,
	/** 警官证 */
	POLICE_OFFICER_CERTIFICATE = 16,
	/** 临时身份证 */
	TEMPORARY_ID_CARD = 17,
	/** 士兵证 */
	SOLDIER_ID_CARD = 18,
	OTHERS = 99,

	MEDICAL_DOCUMENT = '-117',//病例资料


	BUSINESS_PROPOSAL_SIGN_HOLDER = '-10', //业务投保单投保人签名PDF
	BUSINESS_PROPOSAL_SIGN_INSURED = '-11', //业务投保单投被保人签名PDF
	BUSINESS_PROPOSAL_SIGN_AGENT = '-12', //业务投保单代理人签名PDF

	/** 客户告知书签名 */
	CLIENT_NOTICE_SIGNATURE = '-129',
	CLIENT_NOTICE_SIGNATURE_IMAGE = '-130',
	CLIENT_NOTICE_SIGNATURE_PDF = '-132',
	/** 保险经纪服务委托合同签名 */
	CONSIGNMENT_AGREEMENT_SIGNATURE = '-134',
	CONSIGNMENT_AGREEMENT_SIGNATURE_IMAGE = '-135',
	CONSIGNMENT_AGREEMENT_SIGNATURE_PDF = '-137',
	//未成年投保授权书
	UNDERAGE_PROPOSAL_IMAGE = '-199',
	//被保险人父母身份证明
	INSURED_PARENTS_IDTIFY = '-200',
	//被保险人母亲/父亲身故证明
	INSURED_PARENTS_DEAD_IDTIFY = '-201',
	//监护人关系证明
	INSURED_RELATIONSHIP_IDTIFY = '-202',
	//监护授权委托协议
	TUTELAGE_AGREEMENT = '-203',
	//授权人身份证明文件
	AUTHOR_IDTIFY = '-204',
	//被保险人父母与被保险人关系证明
	INSURED_PARENTS_RELATIONSHIP_IDTIFY = '-205',
	PROPOSAL_CONFIRM_SIGN_HOLDER = '-206', //业务投保单确认书投保人签名PDF
	PROPOSAL_CONFIRM_SIGN_INSURED = '-207', //业务投保单确认书投被保人签名PDF
	PROPOSAL_SIGN_HOLDER = '-208', //业务投保单确认书投保人签名PDF
	PROPOSAL_SIGN_INSURED = '-209', //业务投保单确认书投被保人签名PDF
	/** 签名 */
	BANK_CARD = '-1',
	SIGNATURE = '-2',
	/** 回执 */
	RECEIPT_SIGNATURE = '-89',
	/** 人脸识别 */
	FACE_RECOGNIZE = '-601',
	/**人身保险投保提示书*/
	INSURANCE_NOTICE_SIGNATURE = '-139',
	/**免除保险人责任条款说明书*/
	WAIVER_INSURER_LIABILLITY_SIGNATURE = '-140',
	/** 人身投保提示书代理人&投保人PDF*/
	PERSONAL_INSURANCE_REMINDER_AGENT_HOLDER_PDF = '-71',
	/**个人税收声明投保人签名PDF-投保人持有 */
	PERSONAL_TAX_PDF_HOLDER = '-97',

	/**非大陆人士问卷 */
	FOREIGN_QUESTION_SURVEY = '-801',
	/**免除保险人责任条款的说明书 */
	LIABILITY_EXEMPTION_CLAUSES = '-811',
	/**方法详情及方案选择表 */
	CASE_DETAIL_SELECTION = '-812',
	/**重庆地区问卷*/
	CHONGQING_QUESTION_SURVEY = '-804',
	/**重庆地区风险提示*/
	CHONGQING_RISK_HINT = '-807',
	/**保险款项自动转账授权书*/
	BANK_AUTO_TRANSFER_ANTHORIZATION_SURVEY = '-805',
	/**保险款项自动转账授权书*/
	INSURANCE_CONFIRM_FORM = '-806',
	INSURED_CONFIRM_SIGN_IMAGE = '-1000',//被保人确认函，信息确认签字
	INSURED_CONFIRM_PDF = '-1001',//被保人确认函，信息确认签字
	INSURANCE_TIPS_SIGN_PICTURE = '-1003',//投保提示书签字影像
	INSURANCE_TIPS_PICTURE = '-1002',//投保提示书影像
	FACE_DETECT_CAPTURE = '-1100',//人脸识别过程中的截图
}

export type PolicyDocument = {
	documentType: PolicyDocumentType;
	front?: boolean;
	url?: string;
	uri?: string;
	signedDocId?: string | number;
	isComplete?: boolean;
};
export type SignatureDocument = {
	isComplete?: boolean;
	image?: string;
	signDate?: string;
	signImage?: string;
	signComplete?: boolean;
	given?: {};
};

export enum SignatureStatus {
	/** 待签名 */
	PRE_SIGN = '1',
	/** 获取验证码 */
	SEND_CODE = '2',
	/** 已短信验证 */
	VALIDATION = '3',
	/** 已签名 */
	SIGNED = '4',
	/** 重签 */
	RE_SIGN = '5'
}

export enum SmsType {
	/**客户签名短信 */
	NOTIFY_CUSTOMER_SIGN = '1',
	/**通知代理人投保人签名成功,被保人继续签名 */
	NOTIFY_AGENT_PH_SIGN_SUCCESS_AND_CONTINUE = '2',
	/**通知代理人被保人签名成功 */
	NOTIFY_AGENT_INSURED_SIGN_SUCCESS = '3',
	/**通知代理人投保人签名链接失效 */
	NOTIFY_AGENT_PH_SIGN_OVERDUE = '4',
	/**通知代理人被保人签名链接失效 */
	NOTIFY_AGENT_INSURED_SIGN_OVERDUE = '5',
	/**通知代理人投保人签名成功 */
	NOTIFY_AGENT_SIGN_SUCCESS = '6',
	/**通知代理人投保人签名回执成功 */
	NOTIFY_AGENT_RECEIPT_SIGN_SUCCESS = '7',
	/**商城客户短信 */
	SIGNIN_CUSTOMER_FOR_MALL = '8',
	/**分享链接客户验证短信 */
	POLICY_SHARE_CUSTOMER_FOR_MALL = '9',
	/**客户人脸识别短信 */
	NOTIFY_CUSTOMER_FACERECONGNIZE = '10',
	/**分享保单链接客户验证短信 */
	POLICY_VIEW_SHARE_CUSTOMER_FOR_MALL = '11',
	/**C端公众号绑定短信 */
	REGISTER_CUSTOMER_FOR_TOC = '12',
	/**C端公众号解绑短信 */
	UNBIND_CUSTOMER_FOR_TOC = '13',
	/**通知代理人,被保人身份验证成功 */
	POLICY_CHECK_INSURED_FOR_CUSTOMER = '14',
	/**通知投保人,被保人身份验证成功 */
	POLICY_CHECK_INSURED_FOR_AGENT = '15',
	/**投保人撤单验证短信 */
	POLICY_WITHDRAW_FOR_HOLDER_SMS_SEND = '20'
}


export enum SignatureSource {
	POLICY = "POLICY", //"保单"),
	ADDON = "ADDON",//"永达理单证"),
	FACERECONGNIZE = "FACERECONGNIZE", //"人脸识别" );
}


export type ParticipantSignatureDocument = {
	documentType:
	PolicyDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE
	| PolicyDocumentType.BUSINESS_PROPOSAL_SIGN_HOLDER
	| PolicyDocumentType.BUSINESS_PROPOSAL_SIGN_INSURED
	| PolicyDocumentType.RECEIPT_SIGNATURE
	| PolicyDocumentType.CLIENT_NOTICE_SIGNATURE
	| PolicyDocumentType.SIGNATURE
	| PolicyDocumentType.PERSONAL_TAX_PDF_HOLDER
	| PolicyDocumentType.PERSONAL_INSURANCE_REMINDER_AGENT_HOLDER_PDF
	| PolicyDocumentType.LIABILITY_EXEMPTION_CLAUSES
	| PolicyDocumentType.FOREIGN_QUESTION_SURVEY
	| PolicyDocumentType.CASE_DETAIL_SELECTION
	| PolicyDocumentType.CLIENT_NOTICE_SIGNATURE_PDF
	| PolicyDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE_PDF
	| PolicyDocumentType.CHONGQING_QUESTION_SURVEY
	| PolicyDocumentType.BANK_AUTO_TRANSFER_ANTHORIZATION_SURVEY
	| PolicyDocumentType.INSURANCE_CONFIRM_FORM
	| PolicyDocumentType.PROPOSAL_CONFIRM_SIGN_HOLDER
	| PolicyDocumentType.PROPOSAL_CONFIRM_SIGN_INSURED
	| PolicyDocumentType.PROPOSAL_SIGN_HOLDER
	| PolicyDocumentType.PROPOSAL_SIGN_INSURED
	| PolicyDocumentType.INSURED_RELATIONSHIP_IDTIFY
	| PolicyDocumentType.CHONGQING_RISK_HINT;
} & SignatureDocument;
export type ParticipantSignatureCADocument = {
	documentType:
	| PolicyDocumentType.CLIENT_NOTICE_SIGNATURE_PDF
	| PolicyDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE_PDF;
	signedDocId?: string | number;
	ossLocation?: string;
	url?: string;
	uri?: string;
};
export type Participant = {
	/** 名. 中文为全名 */
	firstName?: string;
	/** 姓 */
	lastName?: string;
	/** 全名 */
	fullName?: string;
	/** 性别 */
	gender?: Gender;
	/** 出生日期 */
	dateOfBirth?: string;
	/** 证件类型 */
	idType?: ParticipantIdType | string;
	/** 证件号码 */
	idNo?: string;
	/** 移动电话 */
	mobile?: string;
	/** 邮件地址 */
	email?: string;
	/** 省份代码 */
	provinceCode?: string;
	/** 城市代码 */
	cityCode?: string;
	/** 区县代码 */
	regionCode?: string;
	/** 地址1 */
	addressLine1?: string;
	/** 邮政编码 */
	postCode?: string;
	extensionData?: {
		age?: number;
		/** 工作单位 */
		workUnit?: string;
		/** 年收入(元) */
		annualIncome?: string | number;
		/** 收入来源 */
		incomeSource?: string | number;
		/** 告知 */
		declarations?: ParticipantDeclaration[];
		/** 上传文档 */
		documents?: (
			| PolicyDocument
			| ParticipantSignatureDocument
			| ParticipantSignatureCADocument)[];
		signature?: {
			signatureId?: string | number;
			signatureStatus?: SignatureStatus;
			url?: string;
		};
		/** 一字多签被保人签名是否完成 */
		signComplete?: boolean;
		/** 被保人签名base64 */
		signImage?: string;
		/** 被保人签名日期 */
		signDate?: string;
		/** 国籍*/
		nationality?: string;
		/** 职业类别code*/
		jobCateCode?: string;
		/**与投保人关系 */
		relationWithPH?: string;
		/** 居民税收身份证明 */
		taxDweller?: TaxDweller;
		/** 身高 */
		height?: string;
		/**体重 */
		weight?: string;
		/**婚姻状态 */
		maritalStatus?: string;
		[propName: string]: any;
	};
};
export type TaxDweller = {
	taxType?: string;
}

export enum PolicyCustomerType {
	/** 投保人 */
	HOLDER = 1,
	/** 被保人 */
	INSURED = 2,
	/** 受益人 */
	BENEFICIARY = 3,
	/** 预约客户 */
	PROSPECT = 5

}

export type CoverageFeeModel = {
	app?: string;
}

export type InsuredParticipant = {
	/** 序列号 */
	insuredParticipantId?: number;
	/** 参与者类型 */
	customerType: PolicyCustomerType.INSURED;
	extensionData?: {
		partySerialId?: string | number;
		/** 与投保人关系 */
		relationWithPH?: RelationTypeWith;
		/**附加被保人与主被保人关系，受益人与被保人关系 */
		relationWithInsured?: string;
		signatureEnabled?: boolean;
		csModel?: CSModel;
	};
} & Participant;

export enum BeneficiaryType {
	/** 死亡受益人 */
	DEATH = '1',
	/** 祝寿金受益人 */
	BIRTH_CONGRATULATE = '4',
	/** 医疗给付受益人 */
	MEDICAL_BENEFICIARY = '9'
}

export type BeneficiaryParticipant = {
	/** 序列号 */
	insuredParticipantId?: number;
	/** 参与者类型 */
	customerType: PolicyCustomerType.BENEFICIARY;
	extensionData?: {
		partySerialId?: string | number;
		/** 受益类型 */
		beneficiaryType?: BeneficiaryType;
		/** 是否法定受益人 */
		legalIndi?: 'Y' | 'N' | null;
		sameWithPH?: 'Y' | 'N' | null;
		/** 受益顺序 */
		benefitOrder?: number;
		/** 受益比例 */
		benefitRatio?: number;
	};
} & Participant;
export type CoverageModel = {
	/**险种主键 */
	coverageId?: string;
	/** 险种代码 */
	coverageCode?: string;
	/** 险种名称 */
	coverageName?: string;
	/** 险种类型, R: 附加险, M: 主险 */
	coverageType?: 'R' | 'M';
	coverageFee?: CoverageFeeModel;
	extensionData?: {
		/** 险种类型, R: 附加险, M: 主险 */
		coverageType?: 'R' | 'M';
		/** 缴费期间 */
		installment?: string;
		/**份数 */
		unit?: string;
		/** 交费频率 */
		paymentFreq?: string;
		/**档次 */
		benefitLevel?: string;
		sumInsured?: string | number;
		/** 险种是否被选择 */
		enabled?: boolean;
		/**保障期限 */
		coveragePeriod?: string;
		isWaiver?: string;
		autoRenewalIndi?: 'Y' | 'N' | null;
		/**险种责任code */
		responsibility?: string;
		//责任
		liabilityList?: LiabilityModel[];
		//外部保司code
		externalCode?: string;
		//计划
		planCode?: string;
		//文档
		documents?: (
			| PolicyDocument
			| ParticipantSignatureDocument
			| ParticipantSignatureCADocument)[];
	};
	[propName: string]: any;
};

export type LiabilityModel = {
	liabilityCode: string;
	selected: 'Y' | 'N' | true | false | null;
	value: 'Y' | 'N' | true | false | null;
}
export type InsuredModel = {
	insuredId?: string | number;
	/** 标的名称, 可以是人名, 牌照等等 */
	insuredName?: string;
	/** 参与方 */
	participants?: (InsuredParticipant | BeneficiaryParticipant)[];
	/** 险种 */
	coverages?: CoverageModel[];
	extensionData?: {
		/** 是否是主标的 */
		mainInsured?: 'Y' | 'N';
		/** 是否是法定受益人. N:法定; Y:指定 */
		hasDesignatedBeneficiary?: 'Y' | 'N';
		/** 是否是法定受益人. Y:是; N: 否*/
		legalIndi?: 'Y' | 'N';
		/** 显示顺序 */
		displayOrder: number;
		/**index */
		insuredIndex?: number;
	};
	[propName: string]: any;
};

// export enum CLPCPPolicyStatus {
// 	WAIT_FOR_SUBMIT = '11',
// 	QUOTE_SUBMITTED = '51',
// 	AUTO_UW_PASS = '106',
// }

export enum PolicyDetailStatus {
	DELETE_CATEGORY = "0",// "删除"),
	DELETE = "1", //"删除", "0"),
	WAIT_FOR_SUBMIT_CATEGORY = "10", //"待提交"),
	WAIT_FOR_SUBMIT = "11", //"待提交", "10"),
	SUBMITTED_CATEGORY = "50", //"投保提交"),
	SUBMITTED = "51", //"投保提交", "100"),
	SUBMITTED_FAILED = "52", //"投保提交失败"),
	QUOTED_CATEGORY = "100", //"承保中"),
	QUOTE_MANUAL_UW = "101", //"人工核保中", "100"),
	QUOTE_FAILED = "102", //"投保失败", "100"),
	QUOTE_CANCEL = "103", //"撤单", "100"),
	WAITING_FOR_COLLECTION = "114", //"待支付"),
	QUOTE_TRANSFERRING = "104", //"转账收费中", "100"),
	QUOTE_TRANSFER_FAILED = "105",// "转账失败", "100"),
	QUOTE_AUTO_UW_PASS = "106", //"自核通过", "100"),
	QUOTE_UW_PASS = "107", //"核保通过", "100"),
	QUOTE_TRANSFER_SUCCESS = "108",// "转账成功", "100"),
	QUOTE_OTHER = "109", //"其他", "100"),
	QUOTE_UW_1 = "110", //"核保中", "100"),
	QUOTE_UW_2 = "111", //"核保中", "100"),
	QUOTE_AUTO_UW_FAILED = "112", //"自核失败", "100"),
	QUOTE_REFUNDING = "120",// "退费中", "100"),
	QUOTE_REFUND_SUCCESS = "121",// "退费成功", "100"),
	QUOTE_REFUND_FAILED = "122", //"退费失败", "100"),
	COLLECTION_APPLY_PENDING = "123",// "支付申请中", "100"),
	ISSUED_CATEGORY = "200", //"承保"),
	ISSUED = "201", //"已承保", "200"),
	ISSUED_RECEIPT_RETURNED = "202",// "已回执", "200"),
	ISSUED_RECEIPT_AUDITING = "203", //"回执审核中", "200"),
	ISSUED_RECEIPT_DONE = "204", //"已回访", "200");
	EXIT_THE_INSURED = "504",//已退保
	EXIT_THE_INSURED_DURING_HESITATION_PERIOD = "505" // 犹豫期退保
}

export enum PolicyTempStatus {
	QUOTE_MANUAL_UW = "101", // 人工核保中, submitPolicy/policyId
	QUOTE_AUTO_UW_PASS = "106", // 自核通过, 调用保司影像上传（wanxiang），调收银台（李琦）
}

/**
 *  1现金
	2支票
	3银行转账
	4信用卡
	5微信支付
	6线下转账
	7银联网页支付
	999其他
 */
export enum PaymentMethod {
	BATCH_OFFLINE = '6',
	REAL_TIME = '3',
	CASH = '1',
	CHEQUE = '2',
	CREDIT_CARD = '4',
	WECHAT_PAY = '5',
	UNION_WEB_PAY = '7',
	OTHER = '999'
}

export enum PayType {
	REAL_TIME_DEDUCTION = '1',//实时扣款
	BANK_APPROVED_DEDUCTION = '2'//银行批扣
}

/**
 * 签名方式
 */
export enum SignatureMethod {
	LOCAL = '1',
	SHARE = '2'
}

export enum AgentDocumentType {
	/** 保险经纪服务委托合同签名 */
	CONSIGNMENT_AGREEMENT_SIGNATURE = '-134',
	CONSIGNMENT_AGREEMENT_SIGNATURE_IMAGE = '-135',
	/** 代理人签名 */
	AGENT_SIGNATURE = '-2',
	/**人身保险投保提示书*/
	INSURANCE_NOTICE_SIGNATURE = '-139'
}

export type CaPositionKeywordModel = {
	keyword: string;
	offsetX: number;
	offsetY: number;
	page: number;
	times: number;
}

export type CaPositionModel = {
	height: number;
	keyword: CaPositionKeywordModel,
	page: number;
	positionType: string;
	width: number;
}

export type CaCommandModel = {
	imageUrl?: string;//传oss地址
	base64Image: string;
	email: string;
	idNo: string;
	idNoType: string;
	mobile: string;
	positions: CaPositionModel[],
	realName: string;
}

export type AgentDocument = {
	documentType:
	| AgentDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE
	| AgentDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE_IMAGE
	| AgentDocumentType.INSURANCE_NOTICE_SIGNATURE
	| AgentDocumentType.AGENT_SIGNATURE;
	url?: string;
} & SignatureDocument;

export type PaymentInfo = {
	/**付款方式 */
	paymentMethod?: PaymentMethod;
	/** 银行code */
	bankCode?: string;
	/** 银行卡号 */
	bankCard?: string;
	/** 开户省code*/
	bankAccountProvinceCode?: string;
	/** 开户市code*/
	bankAccountCityCode?: string;
}

export type PolicyModel = {
	policyId?: number;
	quotationNo?: string;
	policyNo?: string;
	insureds?: InsuredModel[];
	policyHolder?: Participant & {
		extensionData?: {
			receiptSignature?: { signatureId?: number | string };
			status?: string;
		};
	};
	sourceType?: SourceType;
	extensionData?: {
		/** CLPCP明细状态 */
		status?: PolicyDetailStatus;
		tempStatus?: PolicyTempStatus;
		/** 当前UI步骤名称 */
		step?: string;
		documents?: {
			documents?: AgentDocument[];
		};
		rootUrlFrom?: string;
		hasExemptionInsurance?: string;
		urlFrom?: string;
		/** 当前正在签名的序列号 */
		signatureId?: string | number;
		/** 验证码 */
		validateCode?: string;
		receiptMethod?: ReceiptMethod;
		receipt?: string;
		/** IMPORTANT 临时图片, 用于显示某张图片用, 不持久化, 用完需要被删掉 */
		tempImage?: string;
		/** IMPORTANT 临时年龄, 用于显示文案信息判断, 不持久化, 用完需要被删掉 */
		tempAge?: string;
		/** 保单扩展字段是否双录字段 */
		needDoubleRecording?: boolean;
		/** 根供应商 */
		rootVendorCode?: string;
		/**支付信息 */
		paymentInfo?: PaymentInfo;
		/**保全模型 */
		csModel?: CSModel;
		/**保全action */
		csAction?: CsAction;
		/**临时 */
		tempInsuredId?: number;
		/**临时 */
		tempInsuredParticipantId?: number;
		/**临时 */
		tempCoverageId?: number;
		//微信小程序openid
		openid?: string;
		gluttonAllSteps?: string[];
		/**代理人在保司的code */
		companyAgentCode?: string;
		companyAgentName?: string;
		/** 公司信息 */
		companyName?: string;
		companyCode?: string;
		agentSalesCode?: string;
		[propName: string]: any;
	};
	[propName: string]: any;
};

//回执方式
export enum ReceiptMethod {
	LOCAL = '1',
	SHARE = '2'
};

export enum FaceRecongnizeRoleType {
	POLICY_HOLDER = 'PROPOSER',
	POLICY_INSURED = 'INSURED',
	POLICY_BENEFICIARY = 'BENEFICIARY'
};

// 保单模型 ********************************************


export enum CsAction {
	//变更银行卡信息
	CHANGE_BANK_INFO = 'changeBankInfo',
}

//保全时的模型
export type CSModel = {
	documents?: (
		| PolicyDocument
		| ParticipantSignatureDocument
		| ParticipantSignatureCADocument)[];
	[propName: string]: any;
}

export enum PolicyStepName {
	STEP_INTRO = 'intro',//产品详情
	STEP_ADVICE_PARTICIPANTS = 'advice-participants',//计划书投被保人信息录入
	STEP_ADVICE_COVEAGES = 'advice-coverages',//计划书制定
	STEP_ADVICE = 'advice',//计划书
	STEP_NOTICE = 'notice',//投保须知
	STEP_HEALTH_NOTICE = 'health-notice',//健康告知
	STEP_OUTLINE_PARTICIPANTS = 'outline-participants',//投被保人信息录入
	STEP_PARTICIPANTS = 'participants',//投被保人信息录入
	STEP_COVEAGES = 'coverages',//计划制定
	STEP_HOLDER_FILL = 'holder-fill',//投保人信息录入
	STEP_PERSON_INFO = 'personInfo',//投保人信息录入,复星用
	STEP_INSUREDS_FILL = 'insureds-fill',//被保人信息录入
	STEP_DATA_ENTRY = 'data-entry',//投被保人受益人信息录入
	STEP_HOLDER_DECLARATIONS = 'holder-declarations',//投保人告知
	STEP_INSURED_DECLARATIONS = 'insured-declarations',//被保险人告知
	STEP_IMAGE_UPLOAD = 'image-upload', //影像上传
	STEP_IMAGE_UPLOAD_RETRY = 'image-upload-retry', //影像上传重试
	STEP_RENEWAL_PAYMENT = 'renewal-payment',//续期缴费信息
	GENERATION_CHECK_VIEW = 'generation-check-view',//'影像审核页',
	STEP_REVIEW_ORDER = 'review-order',//核对订单
	STEP_APPLY_CONFIRM = 'apply-confirm',// 核对订单,1an
	STEP_PAYMENT = 'payment',//支付保费
	STEP_UNDERWRITE_FAILED = 'underwrite-failed',//核保失败
	STEP_APPLY_FAILED = 'apply-failed',//投保失败
	IMAGE_UPLOAD_FAILED = 'image-upload-failed',//投保失败
	STEP_ISSUE_FAILED = 'issue-failed',//承保失败
	STEP_PAY_FAILED = 'pay-failed',//支付失败
	STEP_PAY_SUBMIT_SUCCESS = 'pay-submit-success',//支付成功
	STEP_ISSUE_SUCCESS = 'issue-success',//承保成功
	STEP_POLICY_INFO = 'policy-info',//订单详情
	STEP_ORDER_DETAILS = 'order-details',//订单详情
	STEP_POLICY_VIEW = 'policy-view',//保单详情
	STEP_APPLY_PEDDING = 'apply-pedding',//投保轮训
	STEP_SMS_VERIFICATION = 'sms-verification',//短信验证
	STEP_ELECTRONIC_CONFIRM_FORM = 'electronic-confirm-form',//电子投保确认函
	STEP_SIGN_SIGNATURE = 'sign-signature',//签字
	CONFIRM_INSURED_IDENTITY = 'confirm-insured-indentity',//被保人信息
	SMS_SUCCESS = 'sms-success',//被保人验证成功

	PREFIEX_STEP_RATING = 'rating-',//费率表
	PREFIEX_STEP_BANK_TRANSFER_ANTHORIZATION = 'bank-transfer-authorization-',//银行转账授权声明
	PREFIEX_STEP_CUSTOMER_REAL = 'customer-real-',//客户真实性提示
	PREFIEX_STEP_CLAIM_APPLICATION = 'claim-application-',//理赔申请书
	PREFIEX_STEP_MATERIAL_COLLECTION_GUIDE = 'material-collection-guide-',//材料收集指南
	PREFIEX_STEP_PROTECTION_DETAILS = 'protection-details-',//保障详情
	PREFIEX_STEP_POLICY_SAMPLE = 'policy-sample-',//保单样本
	PREFIEX_STEP_AFTER_SALES_NOTICE = 'after-sales-notice-',//售后须知
	PREFIEX_STEP_VALUE_ADDED_SERVICE = 'value-added-service-',//增值服务
	PREFIEX_STEP_OCCUPATION_LIST = 'occupation-list-',//职业列表
	PREFIEX_STEP_HEALTH_SERVICE = 'health-service-',//健康服务
	PREFIEX_STEP_AGREEMENT = 'AGREEMENT-',//委托协议
	PREFIEX_STEP_CUSTOMER = 'CUSTOMER-',//客户告知书
	PREFIEX_STEP_CUSTOMER_EQUITY = 'customer-equity-',//“忠诚客户”权益
	PREFIEX_STEP_CUSTOMER_SERVICE_GUIDE = 'customer-service-guide-',//客户服务指南
	PREFIEX_STEP_STATEMENT_AND_CONFIRMATION = 'statement-and-confirmation-',//声明及确认事项书
	PREFIEX_STEP_STATEMENT = 'statement-',//声明
	PREFIEX_STEP_WITHHOLD_NOTICE = 'withhold-notice-',//扣款知情同意书
	PREFIEX_STEP_HEALTH_GUARD_SERVICES = 'health-guard-services-',//e就医健康卫士尊享服务
	PREFIEX_STEP_CLAUSE = 'clause-',//条款
	PREFIEX_APPLY_NOTICE = 'apply-notice-',//详细投保须知
	PREFIEX_STEP_CUSTOMER_NOTICE = 'customer-notice-',//客户告知书
	PREFIEX_STEP_INFO_DISCLOSURE = 'info-disclosure-',//产品信息披露
	PREFIEX_STEP_INSURANCE_BROKER = 'insurance-broker-',//保险经纪服务委托合同
	PREFIEX_STEP_INSURANCE_NOTICE = 'insurance-notice-',//投保提示书
	PREFIEX_STEP_QUOTE_REMINDER = 'quote-reminder-',//产品投保提示书
	PREFIEX_STEP_QUOTE_RULES = 'quote-rules-',//产品投保规则
	PREFIEX_STEP_REMOVE_LIAB = 'remove-liab-',//产品免责声明
	PREFIEX_STEP_ENABLING_CLAUSE = 'enabling-clause-',//授权条款
	PREFIEX_STEP_TRANSFER_AUTHORIZATION = 'bank-transfer-',//银行转账授权书
	PREFIEX_STEP_RISK_HINT = 'risk-hint-',//风险提示
	PREFIEX_STEP_APPLY_ISSUE_SERVICE_GUIDE = 'apply-issue-service-guide-',//投保及承保服务指南
	PREFIEX_STEP_CS_SERVICE_GUIDE = 'cs-service-guide-',//保全服务指南
	PREFIEX_STEP_CLAIM_SERVICE_GUIDE = 'claim-service-guide-',//理赔服务指南
	PREFIEX_STEP_COMMON_PROBLEM = 'common-problem-',//常见问题


}

export enum ChannelName {
	DEFAULT_APP = "2A",// "E保电投"),
	// E保网销商城
	ESHOP = "eShop-2A", //"E保网销商城"),
	// 江苏有线
	JSCN = "JSCN",// "江苏有线");
	//永保
	WECHAT = "WeChat-2A",// "永保");
	//官网
	OFFICIALWEB = "OfficialWeb",// "官网");
}


/**
 * 保单来源类型
 */
export enum SourceType {
	DEFAULT = '0', //默认
	OFFLINE = '1', //线下单
	MALL = '3', //商城
	MALL_2C = '4' //商城2客户
}

export enum PaymentAuthStatus {
	SIGNED = "0",//已签约
	UNSIGNED = "1", //未签约
	CANCELLED = "2",//已解约
	UN_NEED_SIGNED = "3"//"无需签约");
}

export enum MallProductTags {
	A1 = 'A1',//寿险
	A2 = 'A2',//健康险
	A3 = 'A3',//意外险
	A4 = 'A4',//责任险
	A5 = 'A5',//财产险
	A6 = 'A6',//重疾险
	A7 = 'A7',//医疗险
	A8 = 'A8',//年金险
}

export enum QuoteFailureStatus {
	APPLY_FAIL = '1',
	ISSUE_FAIL = '2',
	PAY_FAIL = '3',
	UW_FAIL = '4',
	DOCUMENT_UPLOAD_FAIL = '5'
}

/** 页面标记位 */
export enum PolicyPageFlagKey {
	BACK = 'BACK',
	QUIT = 'QUIT',
	FAV = 'FAV',
	SHARE = 'SHARE',
	STEP_ON_POLICY = 'STEP_ON_POLICY',
	ADVICE_PERMITTED = 'ADVICE_PERMITTED',
	QUOTE_PERMITTED = 'QUOTE_PERMITTED',
	VIEW_PERMITTED = 'VIEW_PERMITTED'
}

export type OssSmsModel = {
	smsStatus: string;
	expiredDate: string;
}



export type ClaimModel = {
	caseId?: number;
	claimStatus?:string;

}


//微信账号绑定处理接口
export interface IWechatAccountProcessor{
	wechatProcess(): Promise<WechatAccountResult>;
}


export type WXParam = {
	isWXToBind?:boolean;// 未绑定账号情况下是否自动到绑定页面
	isWXToBindZero?: boolean;// 已绑定未登录账号情况下是否自动到绑定页面
	isWXToBindOne?: boolean;// 已绑定已登录账号情况下是否自动到绑定页面
	isWXAutoLoginZero?: boolean; //已绑定未登录状态下是否微信自动登录
	isWXAutoLoginOne?: boolean; //已绑定已登录状态下是否微信自动登录
	isWXIgnoreAuth?: boolean; //是否忽略已登录状态
	isWXToBindWhenAutoLoginFail?: boolean; //自动登录失败是否跳转到解绑页
	isWXShowMsgWhenAutoLoginFail?: boolean; //自动登录失败是否展示消息
	homePathFunc?:(options:{ account: any })=>string; //正常登录之后跳转home页地址
}

export interface IWechatAccount{
	getWXParam(): WXParam;
}



//微信账号状态
export enum WechatAccountStatus {
	UNBINDING = '-1', //已关注 未绑定
	NOT_LOGGED_IN = '0', //已绑定，未登录
	LOGGED_IN = '1', //已绑定，已登录
}

export enum WXSubScribeStatus {
	SUBSCRIBE = '1',
	UN_SUBSCRIBE ='0',
}

//微信返回结果
export type WechatAccountResult = {
	status: WechatAccountStatus | string; //
	openIdMappingCode: string;
	openId: string;
}

