import { StyledPropsTheme } from '@my-types';
import parent from './standard';

export default Object.assign({}, parent, {
	COLOR_PRIMARY: '#ff594a', // 标准色
	COLOR_PRIMARY_LIGHT_O75: 'rgba(255, 89, 74, 0.75)', // 标准色75%透明
	COLOR_PRIMARY_LIGHT_010: 'rgba(255, 89, 74, 0.1)',
	COLOR_PRIMARY_LIGHT: '#ff887d', // 标准色淡10%
	INPUT_FOCUS_BORDER_COLOR: 'rgba(255, 89, 74, 0.8)', // 输入框获取焦点后边框色
	TOGGLE_TRUE_BORDER_COLOR: '#ff594a',
	TOGGLE_TRUE_BACKGROUND_COLOR: '#ff594a',
}) as StyledPropsTheme;
