// EKANGJI
import { StyledPropsTheme } from '@my-types';
import parent from './standard';
export default Object.assign({}, parent, {
	COLOR_PRIMARY: '#FF6622', // 标准色
	COLOR_BACKGROUND:'#fff',
	BANK_COLOR:'#01CBE5',
	FONT_COLOR:'#0A0D2B',
	COLOR_PRICE:'#FF170D',
	FONT_COLOR_LIGHT2:'#9FA1B3',
	COLOR_BACKGROUND_PERISON:' linear-gradient(90deg, rgba(58,228,226,0.53) 0%,rgba(83,192,231,0.53) 100%), url(v2_r2b2b2.png);',

	ALERT_BOX_TOP: '39vh',
	ALERT_HINT_BACKGROUND_COLOR: 'rgba(0,0,0,.7)',
	ALERT_HINT_BORDER_RADIUS: '8px 8px 8px 8px',
	ALERT_HINT_COLOR: '#fff',

	ALERT_FADEAWAY_BACKGROUND_COLOR: 'rgba(0,0,0,.7)',
	ALERT_FADEAWAY_BORDER_RADIUS: '8px 8px 8px 8px',
	ALERT_FADEAWAY_COLOR: '#fff',

	ALERT_SHADOW: '0px 2px 23px 0px rgba(227,228,233,1)',
	ALERT_BUTTON_BAR_A_MAX_WIDTH: '33vw',
	ALERT_BUTTON_BAR_A_MARGIN_BOTTOM: '20px',
	ALERT_BUTTON_BAR_A_BORDER_TOP_LEFT_RADIUS: '12px',
	ALERT_BUTTON_BAR_A_BORDER_TOP_RIGHT_RADIUS: '12px',
	ALERT_BUTTON_BAR_A_BORDER_BOTTOM_LEFT_RADIUS: '12px',
	ALERT_BUTTON_BAR_A_BORDER_BOTTOM_RIGHT_RADIUS: '12px',

	ALERT_BORDER_RADIUS: '2px',

}) as StyledPropsTheme;