import { StyledPropsTheme } from '@my-types';
import parent from './standard';

export default Object.assign({}, parent, {
	COLOR_PRIMARY: '#e26227', // 标准色
	COLOR_PRIMARY_LIGHT_O75: 'rgba(226, 98, 39, 0.75)', // 标准色75%透明
	COLOR_PRIMARY_LIGHT_010: 'rgba(226, 98, 39, 0.1)',
	COLOR_PRIMARY_LIGHT: '#e88354', // 标准色淡10%
	INPUT_FOCUS_BORDER_COLOR: 'rgba(226, 98, 39, 0.8)', // 输入框获取焦点后边框色
	TOGGLE_TRUE_BORDER_COLOR: '#e26227',
	TOGGLE_TRUE_BACKGROUND_COLOR: '#e26227',
}) as StyledPropsTheme;
