import { StyledF } from '@common/3rd';
// 引入font-awesome
import '@fortawesome/fontawesome-free/css/all.css';
import { StyledPropsTheme } from '@my-types';
import * as StyledComponents from './components';
import ChinaRed from './themes/china-red';
import Orange from './themes/orange';
import PortlandRed from './themes/portland-red';
import HuakRed from './themes/huak-red';
import JscnColor from './themes/jscn';
import Standard from './themes/standard';
import Mej from './themes/mej';
import Ekangji from './themes/EKANGJI';
import YuanjianGreen from './themes/yuanjian-green';
import LVJIAN from './themes/LVJIAN';
import zhgj from './themes/zhgj';


type THEMES_TYPE = {
	[propName: string]: StyledPropsTheme;
};
const THEMES = {
	// 兼容旧命名
	'STYLE-CHINA-RED': ChinaRed,
	'CHINA-RED': ChinaRed,
	'PORTLAND-RED': PortlandRed,
	ORANGE: Orange,
	// 新命名
	CHINA: ChinaRed,
	PORTLAND: PortlandRed,
	TIGER: Orange,
	OLYMPICS: Standard,
	// 华凯
	HUAK: HuakRed,
	MEJ: Mej,
	"JSCN-COLOR": JscnColor,
	'YUANJIAN-GREEN': YuanjianGreen,
	'LVJIAN': LVJIAN,
	'EKANGJI': Ekangji,
	ZHGJ: zhgj,
} as THEMES_TYPE;

/**
 * 手机媒体查询
 */
class Phone {
	/** 600以下认为手机, 无论宽高比 */
	/** 宽>高, 900以下认为手机 */
	static ONLY = '(max-width: 599px), (max-width: 899px) and (orientation: landscape)';
	static only = (first: any, ...rest: any[]) => {
		return StyledF.css`
			@media ${Phone.ONLY} {
				${StyledF.css(first, ...rest)}
			},
			@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
				2
				html,body{height: 812px;}
				3
				}			
		`;
	};
}

/**
 * 平板横向媒体查询
 */
class TabletLandscape {
	/** 宽>高, 900以上认为tablet/pad/desk */
	static UP = '(min-width: 900px) and (orientation: landscape)';
	/** 宽>高, 900-1200认为tablet/pad */
	static ONLY = '(min-width: 900px) and (max-width: 1199px) and (orientation: landscape)';

	static only(first: any, ...rest: any[]) {
		return StyledF.css`
			@media ${TabletLandscape.ONLY} {
				${StyledF.css(first, ...rest)}
			}
		`;
	}

	static up(first: any, ...rest: any[]) {
		return StyledF.css`
			@media ${TabletLandscape.UP} {
				${StyledF.css(first, ...rest)}
			}
		`;
	}
}

/**
 * 平板纵向媒体查询
 */
class TabletPortrait {
	/** 宽<高, 600以上认为tablet/pad/desk */
	static UP = '(min-width: 600px) and (orientation: portrait)';
	/** 宽<高, 600-899, 认为tablet/pad */
	static ONLY = '(min-width: 600px) and (max-width: 899px) and (orientation: portrait)';

	static only(first: any, ...rest: any[]) {
		return StyledF.css`
			@media ${TabletPortrait.ONLY} {
				${StyledF.css(first, ...rest)}
			}
		`;
	}

	static up(first: any, ...rest: any[]) {
		return StyledF.css`
			@media ${TabletPortrait.UP} {
				${StyledF.css(first, ...rest)}
			}
		`;
	}
}

/**
 * 平板媒体查询
 */
class Tablet {
	/** 宽<高, 600以上认为tablet/pad */
	/** 宽>高, 900-1200认为tablet/pad */
	static ONLY =
		'(min-width: 600px) and (max-width: 899px) and (orientation: portrait), (min-width: 900px) and (max-width: 1199px) and (orientation: landscape)';
	/** 宽<高, 600以上认为tablet/pad/desk */
	/** 宽>高, 900以上认为tablet/pad/desk */
	static UP = '(min-width: 600px) and (orientation: portrait), (min-width: 900px) and (orientation: landscape)';

	static only(first: any, ...rest: any[]) {
		return StyledF.css`
			@media ${Tablet.ONLY} {
				${StyledF.css(first, ...rest)}
			}
		`;
	}

	static up(first: any, ...rest: any[]) {
		return StyledF.css`
			@media ${Tablet.UP} {
				${StyledF.css(first, ...rest)}
			}
		`;
	}

	static landscape() {
		return TabletLandscape;
	}

	static portrait() {
		return TabletPortrait;
	}
}

/**
 * 桌面媒体查询ƒ
 */
class Desktop {
	/** 宽<高, 900以上认为desk */
	/** 宽>高, 1200以上认为desk */
	static ONLY = '(min-width: 900px) and (orientation: portrait), (min-width: 1200px) and (orientation: landscape)';

	static only(first: any, ...rest: any[]) {
		return StyledF.css`
			@media ${Desktop.ONLY} {
				${StyledF.css(first, ...rest)}
			}
		`;
	}
}

/**
 * 全部的媒体查询
 */
class AllMedias {
	static phone() {
		return Phone;
	}

	static tablet() {
		return Tablet;
	}

	static desk() {
		return Desktop;
	}
}

/**
 * 样式
 */
class Styles {
	/**
	 * 获取主题定义, 没有命中则返回标准主题.
	 *
	 * @param {string} name
	 */
	getTheme(name: string): StyledPropsTheme {
		if (name) {
			return THEMES[name.toUpperCase().replace(/_/g, '-')] || Standard;
		} else {
			return Standard;
		}
	}

	/**
	 * 根据给定的主题, 创建全局样式表
	 */
	createGlobalStyles(name: string): React.ComponentClass<any> {
		const define = this.getTheme(name);

		return StyledF.createGlobalStyle`
			*,
			*:after,
			*:before {
				box-sizing: border-box;
			}

			html {
				font-size: ${define.FONT_SIZE_HTML}px;
				width: 100%;
				min-height: 100%;
				-webkit-text-size-adjust: 100% !important;
				text-size-adjust: 100% !important;
				-moz-text-size-adjust: 100% !important;
				-webkit-tap-highlight-color: transparent;
				overflow-y: scroll;

				${Phone.only`
					padding-right: 0;
				`}
			}

			body {
				position: relative;
				width: 100%;
				min-height: 100vh;
				margin: 0;
				padding: 0;
				color: ${define.FONT_COLOR};
				background: ${define.COLOR_BACKGROUND};
				font-family: ${define.FONT_FAMILY};
				font-weight: ${define.FONT_WEIGHT};
				font-size: ${define.FONT_SIZE}px;
				line-height: ${define.INPUT_HEIGHT}px;
				overflow-x: hidden;

				${Phone.only`
					min-height: ${window.innerHeight}px;
					overflow-y: auto;
				`}
			}

			h1, h2, h3, h4, h5, h6, p, pre {
				margin: 0;
			}

			h6 {
				line-height: ${define.MARKDOWN_BODY_LINE_HEIGHT}px;
				font-size: ${define.MARKDOWN_BODY_FONT_SIZE}px;
				font-weight: ${define.FONT_WEIGHT};
			}

			input {
				&:-internal-autofill-selected,
				&:-internal-autofill-previewed,
				&:-webkit-autofill,
				&:-webkit-autofill:active,
				&:-webkit-autofill:focus,
				&:-webkit-autofill:hover {
					background-color: ${define.INPUT_BACKGRAOUND_COLOR} !important;
					transition-delay: 999999s;
					transition: color 999999s ease-out, background-color 999999s ease-out;
				}
			}
			
			#root {
				display: block;
				position: relative;
				min-height: 100vh;

				padding-bottom: constant(safe-area-inset-bottom);
				/*兼容 IOS<11.2*/
				padding-bottom: env(safe-area-inset-bottom);
				/*兼容 IOS>11.2*/
				
				${Phone.only`
					min-height: ${window.innerHeight}px;
				`}
			}

			#__vconsole .vc-switch {
				display: none !important;
			}
		`;
	}
}

export default new Styles();
export { StyledComponents };
export { AllMedias, Desktop, Tablet, Phone };
export const DEFAULT_THEME_NAME = 'olympics';
export const asStandardThemeName = (name: string) => {
	switch (name.toLowerCase()) {
		case 'style-china-red':
		case 'china-red':
		case 'china':
			return 'china';
		case 'portland-red':
		case 'portland':
			return 'portland';
		case 'orange':
		case 'tiger':
			return 'tiger';
		case 'huak':
			return 'huak';
		case 'ekangji':
			return 'ekangji';	
		case 'zhgj':
			return 'zhgj';
		default:
			return 'olympics';
	}
};
