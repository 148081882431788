import { React, Styled } from '@common/3rd';
import { FaIconProps } from '@my-types';

const Base = Styled.i`
	text-align: center;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	-webkit-font-feature-settings: normal;
	font-feature-settings: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	width: 1.25em;
	${(props: FaIconProps) => (props.block ? 'display: block;' : '')}
	${props => (props.block ? 'position: relative;' : '')}
	${props => {
		switch (props.color) {
			case 'warn':
				return `color: ${props.theme.COLOR_WARN}`;
			case 'success':
				return `color: ${props.theme.COLOR_SUCCESS}`;
			case 'info':
				return `color: ${props.theme.COLOR_INFO}`;
			case 'error':
				return `color: ${props.theme.COLOR_ERROR}`;
			case 'question':
				return `color: ${props.theme.COLOR_QUESTION}`;
			default:
		}
	}}
	${props => (props.spin ? 'animation: fa-spin 2s infinite linear;' : '')}
	${props => (props.css ? props.css(props, (props as any).theme) : '')}
`;

/**
 * font-awesome solid
 */
const Fas = Styled(Base).attrs({
	'data-widget': 'fas'
})`
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
`;

/**
 * font-awesome regular
 */
const Far = Styled(Base).attrs({
	'data-widget': 'far'
})`
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
`;

/**
 * font-awesome brands
 */
const Fab = Styled(Base).attrs({
	'data-widget': 'fab'
})`
	font-family: 'Font Awesome 5 Brands';
`;

const build = (Node: any) => {
	return class extends React.Component<FaIconProps> {
		render() {
			const props = Object.assign({}, this.props) as FaIconProps;
			const className = props.className
				? `${props.className} fa-${props.icon}`
				: `fa-${props.icon}`;
			delete props.className;
			return <Node className={className} {...props} />;
		}
	};
};

export const FAS = build(Fas);
export const FAR = build(Far);
export const FAB = build(Fab);
